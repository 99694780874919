import React, { useEffect, useState } from 'react';

import { Box, Container, Hidden, Typography } from '@material-ui/core';

import { TitlePage } from '../../../Components/Customs';
import Header from '../../../Components/Header';
import api from '../../../Services/api';
import styleSingleNotice from './style';
import Slider from 'react-slick';
import Footer from '../../../Components/Footer';
import styleBanner from '../../../Components/Banner/style';

import ReactGa from 'react-ga'

function SampleNextArrow(props) {

	const { className, estilo, onClick } = props;

	return (
		<Box onClick={onClick} className={`${estilo.slideNextArrow} ${estilo.slideArrow}`}>
			{">"}
		</Box>
	)
}

function SamplePrevArrow(props) {

	const { className, estilo, onClick } = props;

	return (
		<Box onClick={onClick} className={`${estilo.slidePrevArrow} ${estilo.slideArrow}`}>
			{"<"}
		</Box>
	)
}

function CarouselPost({ gallery }) {

	const [images, setImages] = useState('')

	const style = styleBanner()

	function getImages() {
		const arrImg = gallery.split(',')
		const boxImages = arrImg.map((i) => (
			<Box key={i} >
				<img src={i} alt="" />
			</Box>
		))
		setImages(boxImages)
	}

	useEffect(() => {
		getImages()
	}, [])

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		focusOnSelect: false,
		nextArrow: <SampleNextArrow estilo={style} />,
		prevArrow: <SamplePrevArrow estilo={style} />,
		responsive: [
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
					nextArrow: "",
					prevArrow: ""
				}
			},
		]
	};

	return (
		<Slider {...settings}>
			{images}
		</Slider>
	)
}

function SingleNotice({ match }) {

	const style = styleSingleNotice()

	const [notice, setNotice] = useState(false)
	const [notFound, setNotFound] = useState(false)

	async function getNotice() {
		if (match.params.name) {
			const id = match.params.name.split('-').pop()
			try {
				const res = await api.get(`post/${id}`)
				setNotice(res.data)
			} catch (error) {
				setNotFound(true)
			}
		}
	}

	useEffect(() => {
		getNotice()

		ReactGa.pageview(`/notícia/${match.params.name}`)
	}, [])

	return (
		<>
			<Header />
			<Container maxWidth='md' className={style.root}>
				{notice &&
					<Box className={'box_notice'}>
						{parseInt(notice.show_title) !== 1 &&
							<Box display='flex' justifyContent='center' className='image_notice'>
								<Hidden smDown>
									<Box display='flex' justifyContent='center' alignItems='center'>
										<img src={notice.image} alt={notice.title} />
									</Box>
								</Hidden>

								<Hidden mdUp>
									<Box display='flex' justifyContent='center' alignItems='center'>
										<img src={notice.image_mobile} alt={notice.title} />
									</Box>
								</Hidden>
							</Box>
						}


						<Box className='box_content_notice'>
							{parseInt(notice.show_title) !== 1 && <TitlePage className='title_notice'>{notice.title}</TitlePage>}
							<Box className='content_notice'>
								<Typography component='div' dangerouslySetInnerHTML={{ __html: notice.content }} className={style.joditEditor} />
							</Box>
						</Box>

						{notice.gallery &&
							<Box display='flex' justifyContent='center'>
								<Box className="gallery">
									<CarouselPost gallery={notice.gallery} />
								</Box>
							</Box>
						}

					</Box>
				}
			</Container>

			<Footer />
		</>
	)
}

export default SingleNotice;