import React, { useEffect, useState } from 'react';

import Manager from '../../../Components/Manager';

import { Box, Container, Dialog, DialogActions, DialogContent, DialogTitle, Hidden, Typography } from '@material-ui/core';
import { ContentManager, CustomButtom, IconCustom, TitleList } from '../../../Components/Customs';

import stylePagesManager from '../style';
import api from '../../../Services/api';
import { Add, ArrowBack, CheckCircle, Delete, Edit } from '@material-ui/icons';
import { toast } from 'react-toastify';

function Banners() {

	const [rows, setRows] = useState([])
	const [idBanner, setIdBanner] = useState()
	const [modalDelete, setModalDelete] = useState(false)

	const style = stylePagesManager()

	async function getBanners() {
		const res = await api.get('banner/listAll')
		setRows(res.data)
	}

	function handleModalDelete(id) {
		setIdBanner(id)
		setModalDelete(true)
	}

	function handleClose() {
		setIdBanner(0)
		setModalDelete(false)
	}

	async function deleteBanner() {
		try {
			await api.delete(`banner/${idBanner}`)
			toast.success('Banner deletado com sucesso')
			getBanners()
		} catch (error) {
			toast.error('Falha ao deletar banner')
		}

		handleClose()
	}

	async function activeBanner(id , status){
		try {
			const obj = {
				status: ( status === 'actived' ? 'blocked' : 'actived')
			}
			await api.put( `banner/${id}`, obj)
		} catch (error) {
			console.log(error)
		}
		getBanners()
	}

	useEffect(() => {
		getBanners()
	}, [])

	return (
		<>
			<Manager>
				<Container maxWidth="xl" className={style.root}>
					<Box display='flex' justifyContent='center'>
						<ContentManager width={'90%'}>
							<Box display='flex' justifyContent='space-between' alignItems='center'>
								<TitleList>Banners</TitleList>

								<CustomButtom
									size='large'
									bg='#f4393c'
									startIcon={<Add />}
									className={style.btnAdd}
									href='/admin/banners/novo'
								>
									Adicionar Banner
						</CustomButtom>
							</Box>
							<Box display='flex' justifyContent='center'>
								<table className={style.table} cellSpacing={0} cellPadding={0}>
									<thead>
										<tr>
											<Hidden smDown>
												<th className={`title`}>ID</th>
											</Hidden>
											<th className={`title`}>Imagem</th>
											<th className={`title`}>Título</th>
											<th className={`title settings`}>Opções</th>
										</tr>
									</thead>

									<tbody>
										{rows.map((row) => (
											<tr key={row.id}>
												<Hidden smDown>
													<td width={75} >{row.id}</td>
												</Hidden>
												<td width={150}>
													<Box display='flex' justifyContent='center' className={style.imageBannerList}>
														<img src={row.image_url} alt="" />
													</Box>
												</td>
												<td>{row.title}</td>
												<td className='settings' width={200}>
													<IconCustom coloricon={row.status === 'actived' ? 'green' : '#ccc'} onClick={ () => activeBanner(row.id, row.status)}>
														<CheckCircle />
													</IconCustom>

													<IconCustom coloricon="orange" href={`/admin/banners/editar/${row.id}`}>
														<Edit />
													</IconCustom>

													<IconCustom coloricon="red" onClick={e => handleModalDelete(row.id)}>
														<Delete />
													</IconCustom>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</Box>
						</ContentManager>
					</Box>
				</Container>
			</Manager>

			<Dialog open={modalDelete} onClose={handleClose} aria-labelledby='form-banner' className={style.modalForm}>
				<DialogTitle id='form-banner'>Deletar Banner</DialogTitle>
				<DialogContent>
					<Typography className='content_modal_delete'>Deseja realmente deletar o banner! </Typography>
				</DialogContent>
				<DialogActions>
					<CustomButtom
						bg="orange"
						startIcon={<ArrowBack />}
						onClick={handleClose}
					>
						voltar
						</CustomButtom>

					<CustomButtom
						bg="red"
						endIcon={<Delete />}
						onClick={deleteBanner}
					>
						deletar
						</CustomButtom>
				</DialogActions>
			</Dialog>

		</>
	)
}

export default Banners;