import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../Services/api';
import { DateTime } from 'luxon'

import Banner from '../../Components/Banner';
import Header from '../../Components/Header';
import balao from '../../assets/img/balao.png'

import { Box, Container, Grid, Hidden, Typography } from '@material-ui/core';

import ReactGa from 'react-ga'

import stylePageHome from './style';
import Footer from '../../Components/Footer';

const Home = () => {
	const style = stylePageHome()

	const [posts, setPosts] = useState([])
	const [birthdays, setBirthdays] = useState([])

	const handleSectorEmployee = (sector) => {
		const sectors = {
			"1": 'Escritório',
			"2": 'Loja',
			"5": 'Site',
			"7": 'Galpão',
			"6": 'Nossa Cria',
		}
		return sectors[sector]
	}

	async function getPosts() {
		const res = await api.get('public/post?limit=5')
		setPosts(res.data.rows)
	}

	async function getBirthdays() {
		const res = await api.get('public/date_birth')
		setBirthdays(res.data)
	}

	function verifyDateBirthday(date) {
		const birthday = DateTime.fromISO(date).setLocale('pt').toFormat('dd/MM')
		const today = DateTime.local().setLocale('pt').toFormat('dd/MM')

		if (birthday === today) {
			return 'birthday-now'
		}
	}

	function formatDate(type, date) {
		const d = DateTime.fromISO(date).setLocale('pt')
		if (type === 'post') {
			return `${d.toFormat('dd')} de ${d.toFormat('MMMM')} de ${d.toFormat('yyyy')}`
		}

		if (type === 'birthday') {
			return d.toFormat('dd/MM')
		}
	}

	function formatLink(title, id) {
		const str = title.normalize("NFD").replace(/\s/g, '-').replace(/[^a-zA-Z0-9\-]/g, '').toLowerCase()
		const link = str + '-' + id
		return `/noticia/${link}`
	}

	useEffect(() => {
		getPosts()
		getBirthdays()

		ReactGa.pageview('/destaques')
	}, [])

	return (
		<>
			<Header />

			<Hidden mdUp>
				<Box>
					<Banner id='destaques-mobile' slide />
				</Box>
			</Hidden>

			<Container maxWidth="xl" className={style.root}>
				<Grid container justify="center">
					<Hidden smDown>
						<Grid item xs={12} style={{ padding: '37px 0' }}>
							<Banner id="destaques" slide />
						</Grid>
					</Hidden>

					<Grid container item sm={12} md={9}>

						<Grid container item xs={12} justify="center" className={style.boxNotices}>

							<Grid item xs={12} md={4} className={style.notice}>
								<Banner id="aviso-1" notice className={style.noticeHome} />
							</Grid>

							<Grid item xs={12} md={4} className={style.notice}>
								<Banner id="aviso-2" notice className={style.noticeHome} />
							</Grid>

							<Grid item xs={12} md={4} className={style.notice}>
								<Banner id="aviso-3" notice className={style.noticeHome} />
							</Grid>

						</Grid>

						<Grid item xs={12} className={style.gridBoxPostTired}>
							<Box className={style.boxPostTired}>

								{posts.map(post => (
									<Box key={post.id} component='a' href={formatLink(post.title, post.id)} className={style.postTired} display="flex" >
										<Box className={style.postThumb}>
											<Hidden smDown>
												<img src={post.image} alt={post.title} />
											</Hidden>

											<Hidden mdUp>
												<img src={post.image_mobile} alt={post.title} />
											</Hidden>
										</Box>
										<Box className={style.postTitleDate} display='flex' justifyContent='center' flexDirection='column' >
											<Typography className={style.postTitle}>{post.title}</Typography>

											<Box display='flex' justifyContent='space-between' className={style.postDateReadMore}>
												<Typography className={style.postDate}>{formatDate('post', post.publish_date)}</Typography>
												<Typography className={style.postReadMoreMobile}>leia mais</Typography>
											</Box>
										</Box>
									</Box>
								))}

								<Box display='flex' justifyContent='center'>
									<Link to='/noticias' className={style.linkAllNotices}>leia todas as notícias</Link>
								</Box>

							</Box>
						</Grid>

					</Grid>

					<Grid item xs={12} sm={8} md={3} className={style.gridBoxBirthdays} >
						<Box className={style.boxBirthdays}>
							<Typography className='title-box'>Próximos Aniversáriantes</Typography>

							{birthdays.map((birthday, i) => {
								if (i < 10) {
									return (
										<Box key={birthday.id} display="flex" className={`${style.birthday} ${verifyDateBirthday(birthday.date_birth)}`}>
											<div className={style.birthdayPhoto}>
												<img src={birthday.path_image} alt="" />
											</div>

											<Box display="flex" flexDirection="column" justifyContent="center" className={style.birthdayInfo}>
												<Typography><b>{formatDate('birthday', birthday.date_birth)}</b> - <span>{handleSectorEmployee(birthday.sector_id)}</span> </Typography>
												<Typography>{birthday.name}</Typography>
											</Box>

											{DateTime.fromISO(birthday.date_birth).toFormat('dd/MM') === DateTime.local().toFormat('dd/MM') &&
												<Typography className='balao'>
													<img src={balao} alt="" />
												</Typography>
											}
										</Box>
									)
								}
							})}

							<Box display='flex' justifyContent='center'>
								<Link to='/aniversariantes' className={style.linkAllBirthdays}>veja todos</Link>
							</Box>

						</Box>
					</Grid>
				</Grid>

			</Container>

			<Footer />
		</>
	)
}

export default Home;