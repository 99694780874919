import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Redirect, Route } from 'react-router-dom'
import { store } from '../store'

import { DateTime } from 'luxon'
import NotFound from '../Pages/NotFound'

DateTime.local().setLocale('pt')

const Router = ({ component: Component, isPrivate, user, ...other }) => {

	const { signed } = store.getState().auth
	const { profile } = store.getState().user
	const [hour, setHour] = useState(false)

	useEffect(() => {
		setHour(DateTime.local().hour)
	}, [])

	if (hour) {
		if (hour < 7 || hour > 20) {
			if (other.location.pathname !== '/indisponivel') {
				return <Redirect to={'/indisponivel'} />
			} else {
				return <Route path={'/indisponivel'} component={NotFound} />
			}
		} else {
			if (isPrivate) {

				if (signed) {
					if (user === 'public') {
						return <Route component={Component} user={user} isPrivate={isPrivate} {...other} />
					} else if (user === 'admin') {
						if (profile.type_user === 'admin') {
							return <Route component={Component} user={user} isPrivate={isPrivate} {...other} />
						} else {
							return <Redirect to={{ pathname: '/destaques', state: { from: other.location } }} />
						}
					}
				} else {
					return <Redirect to={{ pathname: '/', state: { from: other.location } }} />
				}
			}

			return <Route component={Component} isPrivate={isPrivate} {...other} />
		}
	}

	return null
}

Router.propTypes = {
	component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
	isPrivate: PropTypes.bool
}

export default Router