const { makeStyles } = require("@material-ui/core");

const stylePageHome = makeStyles((theme) => ({
	root: {
		paddingBottom: 50,

		'& img': {
			maxWidth: '100%',
			height: 'auto',
		}
	},

	boxNotices: {
		'& $notice': {
			'&:nth-child(1)': {
				paddingRight: 25,
			},

			'&:nth-child(2)': {
				paddingRight: 12.5,
				paddingLeft: 12.5,
			},

			'&:nth-child(3)': {
				paddingLeft: 25,
			},
		}
	},

	notice: {
		[theme.breakpoints.down('sm')]: {
			padding: '37px 0 0 0 !important',
		}
	},

	noticeHome: {
		overflow: 'hidden',
		borderRadius: 5,
		display: 'block',
		background: '#fff',
		boxShadow: '-1px 1px 8px -5px #00000082',
		textDecoration: 'none',

		'& .noticeImg': {

			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			overflow: 'hidden',

			[theme.breakpoints.up('md')]: {
				height: 300,
			},

			[theme.breakpoints.down('sm')]: {
				height: 250,
			},

			'& img': {
				[theme.breakpoints.up('md')]: {
					maxWidth: 'max-content',
					height: '115%',
				}
			}
		},

		'& .noticeTitle': {
			overflow: 'hidden',
			color: '#271e1e',
			paddingBottom: 25,


			[theme.breakpoints.up('xl')]: {
				fontSize: 28,
				height: 120,
				marginBottom: 50,
			},

			[theme.breakpoints.down('lg')]: {
				fontSize: '1.4vw',
				height: 135,
			},

			[theme.breakpoints.down('sm')]: {
				fontSize: '3.5vw',
				height: 150,
			},

			[theme.breakpoints.down('xs')]: {
				fontSize: '4vw',
				height: 85,
			}
		},

		'& .noticeInfos': {
			padding: '35px 20px 20px 20px',

			'& .noticeDateReadMore': {
				display: 'flex',
				justifyContent: 'space-between',

				'& .noticeDate, .noticeReadMore': {

					[theme.breakpoints.up('xl')]: {
						fontSize: 20,
					},

					[theme.breakpoints.down('lg')]: {
						fontSize: '1.1vw',
					},

					[theme.breakpoints.down('sm')]: {
						fontSize: '2.5vw',
					},

					[theme.breakpoints.down('xs')]: {
						fontSize: '3vw',
					}
				},

				'& .noticeDate': {
					color: '#271e1e'
				},

				'& .noticeReadMore': {
					color: '#f4393c'
				},


			}
		},
	},


	gridBoxPostTired: {
		paddingTop: 37,
	},

	boxPostTired: {
		background: '#fff',
		boxShadow: '-1px 1px 8px -5px #00000082',
		padding: 30,

		[theme.breakpoints.down('xs')]: {
			padding: 10
		},

	},

	postTired: {
		marginBottom: 20,
		textDecoration: 'none',

		'&:hover': {
			'& $postTitle, $postReadMoreMobile': {
				textDecoration: 'underline'
			}
		}
	},

	postThumb: {
		width: 250,
		height: 175,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',



		[theme.breakpoints.down('sm')]: {
			width: '30%',
			height: 100,
		},

		'& img': {
			[theme.breakpoints.up('md')]: {
				maxWidth: 'max-content',
				height: '100%',
			}
		},

	},

	postTitleDate: {
		paddingRight: 15,
		paddingLeft: 15,
		maxWidth: '70%',
		minWidth: '30%',

		[theme.breakpoints.down('sm')]: {
			paddingRight: 0,
			paddingLeft: 10,
			width: '70%',
		},

	},

	postTitle: {
		color: '#282a31',

		[theme.breakpoints.up('xl')]: {
			fontSize: 28,
		},

		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5vw',
			maxHeight: 75,
			overflow: 'hidden',
		},

		[theme.breakpoints.down('sm')]: {
			fontSize: '3vw',
		},

		[theme.breakpoints.down('xs')]: {
			fontSize: 14,
			maxHeight: 40,
			overflow: 'hidden',
		},
	},

	postDate: {

		color: '#271e1e',

		[theme.breakpoints.up('xl')]: {
			fontSize: 20,
		},

		[theme.breakpoints.down('lg')]: {
			fontSize: '1.1vw',
		},

		[theme.breakpoints.down('sm')]: {
			fontSize: '1.7vw',
		},

		[theme.breakpoints.down('xs')]: {
			fontSize: 10,
		},
	},

	postDateReadMore: {
		[theme.breakpoints.down('xs')]: {
			paddingTop: 0
		},
	},

	postReadMore: {
		'& .MuiTypography-root': {
			width: 90,
			color: '#f4393c',

			[theme.breakpoints.up('xl')]: {
				fontSize: 20,
			},

			[theme.breakpoints.down('lg')]: {
				fontSize: '1.1vw',
			},

			[theme.breakpoints.down('sm')]: {
				fontSize: '1.7vw',
			},

			[theme.breakpoints.down('xs')]: {
				fontSize: 10,
			},

		}
	},

	postReadMoreMobile: {

		[theme.breakpoints.down('sm')]: {
			fontSize: '1.7vw',
		},

		[theme.breakpoints.down('xs')]: {
			fontSize: 10,
		},
		color: '#f4393c'
	},

	gridBoxBirthdays: {

		[theme.breakpoints.up('md')]: {
			paddingLeft: 37,
		},

		[theme.breakpoints.down('sm')]: {
			paddingLeft: 0,
			paddingTop: 37,
		}
	},

	boxBirthdays: {
		background: '#fff',
		boxShadow: '-1px 1px 8px -5px #00000082',
		borderRadius: 5,
		overflow: 'hidden',
		height: '100%',

		'& .title-box': {
			color: '#f4393c',
			fontSize: 27,
			textAlign: 'center',
			padding: '20px 0',
			textTransform: 'uppercase',
			fontWeight: 'bold',

			[theme.breakpoints.up('xl')]: {
				fontSize: 24,
			},

			[theme.breakpoints.down('lg')]: {
				fontSize: '1.3vw',
			},

			[theme.breakpoints.down('sm')]: {
				fontSize: '5.5vw',
			},

			[theme.breakpoints.down('xs')]: {
				fontSize: 20,
			},

		}
	},

	birthday: {
		padding: '10px 15px',
		position: 'relative',

		'&.birthday-now': {
			// background: '#f4393c',
		},

		'& .balao': {
			position: 'absolute',
			top: 10,
			width: 50,
			right: 10,

			'& img': {
				maxWidth: '100%'
			}
		},
	},

	birthdayPhoto: {
		width: 125,

		[theme.breakpoints.down('lg')]: {
			width: '7vw',
		},

		[theme.breakpoints.down('sm')]: {
			width: '30%',
		}
	},

	birthdayInfo: {
		paddingLeft: 15,

		'& .MuiTypography-root, *': {

			[theme.breakpoints.up('xl')]: {
				fontSize: 23,
			},

			[theme.breakpoints.down('lg')]: {
				fontSize: '1.3vw',
			},

			[theme.breakpoints.down('sm')]: {
				fontSize: '4.5vw',
				width: '70%',
			},

			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},

			'& b': {
				color: '#f4393c',
			},

			'& span': {
				color: '#f4393c',
			},

		},
	},

	linkAllBirthdays: {
		textDecoration: 'none',
		color: '#f4393c',
		fontSize: 22,
		margin: '20px 0',

		[theme.breakpoints.down('xs')]: {
			fontSize: 16,
		},

		'&:hover': {
			textDecoration: 'underline',
		}
	},

	linkAllNotices: {
		textDecoration: 'none',
		color: '#f4393c',
		fontSize: 22,

		[theme.breakpoints.down('xs')]: {
			fontSize: 16,
		},

		'&:hover': {
			textDecoration: 'underline',
		}
	}

}))

export default stylePageHome