import React, { useEffect, useState } from 'react';
import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon'

import { CustomButtom, TitleList } from '../Customs';
import { Box, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core';

import styleModalHistory from './style';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import api from '../../Services/api';
import { toast } from 'react-toastify';

DateTime.local().setLocale('pt')

function ModalHistory({ id_employee, openModal, handleModalHistory, id }) {

	const style = styleModalHistory()

	const [data, setData] = useState({
		id_employee: id_employee,
		type: '',
		observation: '',
		date_occurrence: DateTime.local()
	})

	function handleValues(e) {
		const { name, value } = e.target
		setData({ ...data, [name]: value })
	}

	function handleDate(e) {
		setData({ ...data, date_occurrence: DateTime.fromISO(e).toISO() })
	}

	async function getHistory() {
		if (id) {
			const res = await api.get(`employee_history/${id}`)
			setData({
				id_employee: res.data.id_employee,
				type: res.data.type,
				observation: res.data.observation,
				date_occurrence: DateTime.fromSQL(res.data.date_occurrence).toISO()
			})
		}
	}

	async function envHistory(e) {
		e.preventDefault()
		
		const obj = {
			id_employee: id_employee,
			type: data.type,
			observation: data.observation,
			date_occurrence: DateTime.fromISO(data.date_occurrence).toFormat('yyyy-MM-dd')
		}

		if (id) {
			try {
				await api.put(`employee_history/${id}`, obj)
				toast.success('Histórico atualizado com sucesso!')
				handleModalHistory()
			} catch (error) {
				toast.warning('Falha ao atualizar historico!')
			}
		} else {
			try {
				await api.post(`employee_history`, obj)
				toast.success('Histórico cadastrado com sucesso!')
				handleModalHistory()
			} catch (error) {
				toast.warning('Falha ao cadastrar historico!')
			}
		}
	}

	useEffect(() => {
		if (id) {
			getHistory()
		}
	}, [id])

	return (
		<>
			<Dialog open={openModal} onClose={handleModalHistory} className={style.root} aria-labelledby="form-dialog-title">
				<DialogTitle>
					<TitleList>Formulário Histórico</TitleList>
				</DialogTitle>
				<DialogContent>
					<form onSubmit={envHistory} className={style.formHistory}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<FormControl variant='outlined' fullWidth>
									<InputLabel htmlFor='type-history'>Tipo de histórico</InputLabel>
									<Select
										name='type'
										value={data.type}
										id='type-history'
										onChange={handleValues}
										label="Tipo de histórico"
									>
										<MenuItem value="">Selecione um tipo</MenuItem>
										<MenuItem value="Promoção">Promoção</MenuItem>
										<MenuItem value="Falta">Falta</MenuItem>
										<MenuItem value="Atraso">Atraso</MenuItem>
										<MenuItem value="Advertência">Advertência</MenuItem>
										<MenuItem value="Atestado">Atestado</MenuItem>
										<MenuItem value="Aumento Salarial">Aumento Salarial</MenuItem>
										<MenuItem value="Mudança de Setor">Mudança de Setor</MenuItem>
										<MenuItem value="Mudança de Departamento">Mudança de Departamento</MenuItem>
										<MenuItem value="Integração">Integração</MenuItem>
										<MenuItem value="Reciclagem">Reciclagem</MenuItem>
									</Select>
								</FormControl>
							</Grid>

							<Grid item xs={6}>
								<MuiPickersUtilsProvider utils={LuxonUtils} locale='pt-br'>
									<KeyboardDatePicker
										inputVariant="outlined"
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										format="dd/MM/yyyy"
										margin="none"
										id="date_birth"
										label="Data de Nascimento"
										name="date_birth"
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										helperText=""
										value={data.date_occurrence}
										onChange={handleDate}
									/>
								</MuiPickersUtilsProvider>
							</Grid>

							<Grid item xs={12}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel htmlFor='observation' shrink>Observação</InputLabel>
									<OutlinedInput
										multiline
										id='observation'
										name='observation'
										value={data.observation}
										onChange={handleValues}
										rows={6}
										label='Observação'
										notched
									/>
								</FormControl>
							</Grid>

							<Grid item xs={12}>
								<Box display='flex' justifyContent='space-between'>
									<CustomButtom bg='orange' onClick={e => handleModalHistory()}>
										cancelar
									</CustomButtom>

									<CustomButtom type='submit'>
										{id ? 'salvar' : 'cadastrar'}
									</CustomButtom>
								</Box>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
		</>
	)
}

export default ModalHistory;