import React, { useEffect, useState } from 'react';
import api from '../../Services/api';

import { Box, Typography } from '@material-ui/core';
import styleBanner from './style';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { DateTime } from 'luxon';


function SampleNextArrow(props) {

	const { className, estilo, onClick } = props;

	return (
		<Box onClick={onClick} className={`${estilo.slideNextArrow} ${estilo.slideArrow}`}>
			{">"}
		</Box>
	)
}

function SamplePrevArrow(props) {

	const { className, estilo, onClick } = props;

	return (
		<Box onClick={onClick} className={`${estilo.slidePrevArrow} ${estilo.slideArrow}`}>
			{"<"}
		</Box>
	)
}

function createSlide(style, className, imgs) {
	const banners = imgs.map((img) => (
		<Box key={img.id} display='flex' justifyContent='center' alignItems='center' className={`${style.banner} ${className} box-banner`}>
			{img.link ?
				<Box className='box-single-img'>
					<a href={img.link}>
						<img src={img.image_url} alt={img.title} />
						<Typography className={style.titleBannerSlide}>
							<span>{img.title}</span>
							<span>{DateTime.fromSQL(img.date_input).setLocale('pt').toFormat('dd')} de {DateTime.fromSQL(img.date_input).setLocale('pt').toFormat('MMMM')}</span>
						</Typography>
					</a>
				</Box>
				:
				<Box display='flex' justifyContent='center' className='box-single-img' >
					<a href="#">
						<img src={img.image_url} alt={img.title} />
						<Typography className={style.titleBannerSlide}>
							<span>{img.title}</span>
							<span>{DateTime.fromSQL(img.date_input).setLocale('pt').toFormat('dd')} de {DateTime.fromSQL(img.date_input).setLocale('pt').toFormat('MMMM')}</span>
						</Typography>
					</a>
				</Box>
			}
		</Box>
	))

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <SampleNextArrow estilo={style} />,
		prevArrow: <SamplePrevArrow estilo={style} />
	};

	return (
		<Slider {...settings} className={style.slide}>
			{banners}
		</Slider>
	)

}

function createNotice(style, className, imgs) {
	return (
		<>
			{ imgs.length > 0 &&
				<Box component='a' href={imgs[0].link} className={`${style.banner} ${className} box-banner`}>
					<Box className='noticeImg'>
						<img src={imgs[0].image_url} alt="" />
					</Box>
					<Box className='noticeInfos'>
						<Typography className='noticeTitle'>{imgs[0].title}</Typography>
						<Box className='noticeDateReadMore'>
							<Typography className='noticeDate'><span>{DateTime.fromSQL(imgs[0].date_input).setLocale('pt').toFormat('dd')} de {DateTime.fromSQL(imgs[0].date_input).setLocale('pt').toFormat('MMMM')}</span></Typography>
							<Typography className='noticeReadMore'>leia mais</Typography>
						</Box>
					</Box>
				</Box>
			}
		</>
	)
}

function Banner({ id, slide, className, notice }) {

	const style = styleBanner()
	const [imgs, setImgs] = useState([])

	// async function createBanner(){
	// 	const res = await api.post('banner_position', { position_name: id})
	// 	console.log(res)
	// }

	async function getBanner() {
		const res = await api.get(`public/bannerprod/${id}`)
		setImgs(res.data)
	}

	useEffect(() => {
		// createBanner()
		getBanner()
	}, [])

	return (
		<>
			{ slide && createSlide(style, className, imgs)}

			{ notice && createNotice(style, className, imgs)}

			{!slide && !notice && imgs.map((img) => (
				<Box key={img.id} className={`${style.banner} ${className} box-banner`}>
					<img src={img.image_url} alt={img.title} />
				</Box>
			))
			}
		</>
	)
}

export default Banner;