import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import GlobalStyles from './assets/css/GlobalStyles';
import { CssBaseline } from '@material-ui/core';
import Routes from './Routes';
import history from './Services/history';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';
import { ToastContainer } from 'react-toastify';
import { ConnectedRouter } from 'connected-react-router';

import ReactGA from 'react-ga'

function App() {
  useEffect( () => {
    ReactGA.initialize('UA-187822913-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
  })
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <ToastContainer autoClose={3000} />
          <GlobalStyles />
          <CssBaseline />
          <Routes />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
