import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const styleFooter = makeStyles( (theme) => ({
	footer: {
		background: '#f4393c',
		height: 50,
		color: '#fff',
		fontSize: 20,
		marginTop: 100,
		width: '100%',
		position: props => props.fixed ? 'fixed' : 'relative',
		bottom: 0
	}
}))

function Footer(props) {
	
	const style = styleFooter(props)

	return(
		<>
			<Box display='flex' justifyContent='center' alignItems='center' className={style.footer}>
				<Typography>Intranet Loja Santo Antônio</Typography>
			</Box>
		</>
	)
}

export default Footer;