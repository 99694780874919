import React, { useState } from 'react';

import { AppBar, Box, Collapse, Drawer, Hidden, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { AccountBalance, Add, Assessment, BusinessCenter, Close, Dehaze, EventNote, ExitToApp, ExpandLess, ExpandMore, FormatListBulleted, Group, History, Home, Image, PersonAdd, PostAdd, Settings } from '@material-ui/icons';

import Logo from '../../assets/img/intranet_logo.png'
import { IconCustom } from '../Customs';

import { signOut } from '../../store/modules/auth/action';
import { useDispatch } from 'react-redux'


const styleManager = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},

	appBar: {
		background: '#282a31',
		height: 75,
		justifyContent: 'center',
		alignItems: 'center',
	},

	logoInMenu: {
		width: 100,

		'& img': {
			maxWidth: '100%',
			height: 'auto',
		}
	},

	drawerDesk: {
		width: 300,
		flexShrink: 0,

		'& $drawerPaper': {
			width: 300,
		},
	},
	drawerMobile: {
		width: '100vw',
		flexShrink: 0,

		'& $drawerPaper': {
			width: '100vw',
		},
	},

	drawerPaper: {
		background: '#282a31',
		color: '#fff'
	},

	toolbar: {
		position: 'relative',
		height: 80,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},

	content: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing(3),
	},

	navigation: {
		'& *': {
			color: '#fff',
		},

		'& svg': {
			color: '#fff',
		},

		'& .MuiCollapse-container': {
			'& .MuiListItem-gutters': {
				paddingLeft: 30,
			},

			'& .MuiList-root': {
				background: '#35373d',
				borderBottom: '2px solid #fff',
			}
		}
	},

	iconCloseMenuMobile: {
		position: 'absolute',
		right: 15,
	},

	iconMenuMobile: {
		position: 'absolute',
		left: 15,
	},

	content: {
		width: '100%',

		[theme.breakpoints.up("md")]: {
			padding: 37,
			height: '100vh'
		},

		[theme.breakpoints.down("sm")]: {
			paddingTop: 75,
			padding: 0,
		},
	},

	menuHighlight: {
		'& *': {
			color: '#f4393c',
		}
	}
}))

function ListMenu({ handleMenu }) {
	const style = styleManager()
	const [menu, setMenu] = useState({})

	const dispatch = useDispatch()

	function logout() {
		dispatch(signOut())
	}

	return (
		<>

			<div className={style.toolbar}>
				<Hidden mdUp>
					<IconCustom
						className={style.iconCloseMenuMobile}
						coloricon="#fff"
						onClick={handleMenu}
					>
						<Close />
					</IconCustom>
				</Hidden>

				<Box className={style.logoInMenu}>
					<img src={Logo} alt="Intranet Loja Santo Antonio" />
				</Box>
			</div>


			<List
				component="nav"
				className={style.navigation}
			>

				<ListItem button onClick={e => setMenu({ employee: !menu.employee })}>
					<ListItemIcon>
						<Group />
					</ListItemIcon>
					<ListItemText primary="Funcionário" />
					{menu.employee ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={menu.employee} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<ListItem component='a' href='/admin/funcionario/novo'>
							<ListItemIcon>
								<PersonAdd />
							</ListItemIcon>
							<ListItemText primary="Novo funcionário" />
						</ListItem>

						<ListItem component='a' href='/admin/funcionarios'>
							<ListItemIcon>
								<FormatListBulleted />
							</ListItemIcon>
							<ListItemText primary="Lista funcionários" />
						</ListItem>

						<ListItem component='a' href='/admin/funcionarios/avaliacoes'>
							<ListItemIcon>
								<Assessment />
							</ListItemIcon>
							<ListItemText primary="Avaliações" />
						</ListItem>

						<ListItem component='a' href='/admin/timeline'>
							<ListItemIcon>
								<History />
							</ListItemIcon>
							<ListItemText primary="Timeline" />
						</ListItem>
					</List>
				</Collapse>

				<ListItem component='a' href='/admin/cargos'>
					<ListItemIcon>
						<BusinessCenter />
					</ListItemIcon>
					<ListItemText primary="Cargos" />
				</ListItem>

				<ListItem component='a' href='/admin/setores'>
					<ListItemIcon>
						<AccountBalance />
					</ListItemIcon>
					<ListItemText primary="Setores" />
				</ListItem>

				<ListItem component='a' href='/admin/departamentos'>
					<ListItemIcon>
						<AccountBalance />
					</ListItemIcon>
					<ListItemText primary="Departamentos" />
				</ListItem>

				<ListItem button onClick={e => setMenu({ post: !menu.post })}>
					<ListItemIcon>
						<EventNote />
					</ListItemIcon>
					<ListItemText primary="Postagens" />
					{menu.post ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={menu.post} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<ListItem component='a' href='/admin/postagem'>
							<ListItemIcon>
								<PostAdd />
							</ListItemIcon>
							<ListItemText primary="Nova postagem" />
						</ListItem>

						<ListItem component='a' href='/admin/postagens'>
							<ListItemIcon>
								<FormatListBulleted />
							</ListItemIcon>
							<ListItemText primary="Listar postagens" />
						</ListItem>
					</List>
				</Collapse>

				<ListItem button onClick={e => setMenu({ banner: !menu.banner })}>
					<ListItemIcon>
						<Image />
					</ListItemIcon>
					<ListItemText primary="Banners" />
					{menu.banner ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={menu.banner} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<ListItem component='a' href='/admin/banners/novo'>
							<ListItemIcon>
								<Add />
							</ListItemIcon>
							<ListItemText primary="Novo Banner" />
						</ListItem>

						<ListItem component='a' href='/admin/banners'>
							<ListItemIcon>
								<FormatListBulleted />
							</ListItemIcon>
							<ListItemText primary="Listar banners" />
						</ListItem>
					</List>
				</Collapse>

				<ListItem button onClick={e => setMenu({ settings: !menu.settings })}>
					<ListItemIcon>
						<Settings />
					</ListItemIcon>
					<ListItemText primary="Configurações" />
					{menu.settings ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={menu.settings} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<ListItem button onClick={logout}>
							<ListItemIcon>
								<ExitToApp />
							</ListItemIcon>
							<ListItemText primary="Sair" />
						</ListItem>


					</List>
				</Collapse>

				<ListItem className={style.menuHighlight} component='a' href='/destaques' target='_black'>
					<ListItemIcon>
						<Home />
					</ListItemIcon>
					<ListItemText primary="Visitar Intranet" />
				</ListItem>

			</List>
		</>
	)
}


function Manager({ children }) {

	const style = styleManager()
	const [drawerWidth, setDrawerWidth] = useState(300)
	const [open, setOpen] = useState(false)

	function handleMenu() {
		setOpen(!open)
	}

	return (

		<div className={style.root}>
			<Hidden smDown>
				<Drawer
					className={style.drawerDesk}
					variant="permanent"
					classes={{
						paper: style.drawerPaper,
					}}
					anchor="left"
				>
					<ListMenu />
				</Drawer>
			</Hidden>

			<Hidden mdUp>
				<AppBar position="absolute" className={style.appBar}>
					<IconCustom
						coloricon="#fff"
						className={style.iconMenuMobile}
						onClick={handleMenu}
					>
						<Dehaze />
					</IconCustom>
					<Box className={style.logoInMenu}>
						<img src={Logo} alt="Intranet Loja Santo Antonio" />
					</Box>
				</AppBar>
				<Drawer
					className={style.drawerMobile}
					open={open}
					classes={{
						paper: style.drawerPaper,
					}}
					anchor="left"
				>
					<ListMenu handleMenu={handleMenu} />
				</Drawer>
			</Hidden>


			<main className={style.content}>
				{children}
			</main>
		</div>


	)
}

export default Manager;