import axios from 'axios'
import { toast } from 'react-toastify'
import { store } from '../store'
import history from './history'

const api = axios.create({
  //BaseUrl Produção
  baseURL: 'https://api.santolabs.com.br/api/intranet/'

  //BaseUrl local
  // baseURL: 'http://localhost:3002/api/intranet/'
})

api.interceptors.request.use((config) => {
  const { token }  = store.getState().auth

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}, (err) => {
  return Promise.reject(err)
})

api.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 401) {
    toast.warning('Sua sessão expirou!')
    const locationBefore = window.location.href.replace('https://intranet.santolabs.com.br', '')
    history.push({
      pathname: '/',
      state: {
        from: {
          pathname: locationBefore
        }
      }
    })
  }

  return Promise.reject(error)
})

export default api
