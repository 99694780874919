import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon'

import { CustomButtom, TitlePage } from '../../Components/Customs';
import Header from '../../Components/Header';

import stylePageNotices from './style'

import { Box, Container, Grid, Hidden, Typography } from '@material-ui/core';
import api from '../../Services/api';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import history from '../../Services/history';
import { Redirect } from 'react-router-dom';
import Footer from '../../Components/Footer';

import ReactGa from 'react-ga'

function Notices(props) {

	const style = stylePageNotices()
	const [notices, setNotices] = useState({
		count: 0,
		page: 1,
		pages: 1,
		limit: 10,
		rows: []
	})

	async function getNotices(pageView) {
		const res = await api.get(`public/post/listPage?page=${pageView || 1}`)
		setNotices({ count: res.data.count, limit: res.data.limit, pages: parseInt(res.data.pages), page: parseInt(res.data.page), rows: res.data.rows })
	}

	function formateDate(date) {
		const d = DateTime.fromISO(date).setLocale('pt')
		return `${d.toFormat('dd')} de ${d.toFormat('MMMM')} de ${d.toFormat('yyyy')}`
	}

	function formatLink(title, id) {
		const str = title.normalize("NFD").replace(/\s/g, '-').replace(/[^a-zA-Z0-9\-]/g, '').toLowerCase()
		const link = str + '-' + id
		return `/noticia/${link}`
	}

	function handlePagination(type){
		if(type === 'proximo'){
			setNotices({ ...notices, page: ++notices.page})
			history.push({ ...history, search: `?pagina=${notices.page}` })
		}else{
			setNotices({ ...notices, page: --notices.page})
			history.push({ ...history, search: `?pagina=${notices.page}` })
		}
	}

	useEffect(() => {
		if (props.location.search) {
			const page = props.location.search.split('=')

			 getNotices(page[1])
		}else{
			getNotices()
		}
		
	}, [props.location.search])

	useEffect(() => {
		ReactGa.pageview('/notícias')
	}, [])

	return (
		<>
			<Header />

			<Container maxWidth="lg" className={style.root}>
				<TitlePage align='center'>Notícias</TitlePage>
				<Grid container spacing={3}>

					{notices.rows.map((notice) => (
						<Grid key={notice.id} item xs={12}>
							<Box display="flex" component="a" href={formatLink(notice.title, notice.id)} className={style.boxPost}>
								<Box display="flex" justifyContent="center" className={style.thumbPost}>
									<Hidden smDown>
										<img src={notice.image} alt={notice.title} />
									</Hidden>

									<Hidden mdUp>
										<img src={notice.image_mobile} alt={notice.title} />
									</Hidden>

								</Box>

								<Box display="flex" flexDirection="column" justifyContent="space-between" className={style.contentPost}>
									<Box>
										<Typography className="title">{notice.title}</Typography>
										<Typography component="div" dangerouslySetInnerHTML={{ __html: notice.content }} className="resume" />
									</Box>

									<Box display="flex" justifyContent="space-between">
										<Typography className="date-post">{formateDate(notice.publish_date)}</Typography>
										<Typography className="read-more">Leia mais</Typography>
									</Box>
								</Box>
							</Box>
						</Grid>
					))}

					<Grid item xs={12}>
				
						{notices.rows.length > 0 &&
							<Box display='flex' justifyContent={notices.page > 1 ? 'space-between' : 'flex-end'}>
								{notices.page > 1 && <CustomButtom onClick={() => handlePagination('anterior')} startIcon={<ArrowBack />}>Anterior</CustomButtom>}

								{notices.pages > 1 && notices.page < notices.pages && <CustomButtom onClick={() => handlePagination('proximo')} endIcon={<ArrowForward />}>Próximo</CustomButtom>}
							</Box>
						}


					</Grid>

				</Grid>
			</Container>
			<Footer />
		</>
	)
}

export default Notices;