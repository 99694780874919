const { makeStyles } = require("@material-ui/core");

const stylePagesManager = makeStyles((theme) => ({
	root: {
		marginBottom: 50,
	},

	table: {
		borderRadius: 10,
		overflow: 'hidden',
		marginBottom: 50,
		boxShadow: '1px 1px 8px -5px #00000087',
		width: '100%',

		'&.center_header': {
			'& th, td': {
				textAlign: 'center !important',
			}
		},

		'& thead': {
			background: '#f4393c',
			color: '#fff',
		},

		'& th': {
			padding: 15,
			fontWeight: '400',
			fontSize: 18,

			[theme.breakpoints.down('xs')]: {
				fontSize: '3.5vw',
			},
		},

		'& td': {
			padding: '10px 15px',
			fontWeight: '400',
			fontSize: 16,

			[theme.breakpoints.down('xs')]: {

				'&, svg': {
					fontSize: '4vw',
				},
				padding: '5px 15px',

				'& button': {
					padding: '5px !important',
					'& svg': {
						fontSize: '6vw',
					},
				}

			}
		},

		'& tbody tr:nth-of-type(odd)': {
			background: '#f1f1f1',
		},

		'& .title, td': {
			textAlign: "left",
			'&.settings': {
				textAlign: 'center',

				[theme.breakpoints.down('xs')]: {
					width: 100,
				}

			}
		}
	},

	btnAdd: {
		[theme.breakpoints.down('xs')]: {
			'& * ': {
				fontSize: '3vw',
			}
		}
	},

	modalForm: {

		'& .MuiPaper-root': {
			width: 550,
			minHeight: 200,
			padding: '20px 10px',

			[theme.breakpoints.down('xs')]: {
				margin: 10,
			},

			'& > div, form > div': {
				padding: '10px 0',
			},

			'& .MuiDialogContent-root > .MuiFormControl-root': {
				paddingBottom: 15,
			}
		}

	},

	btnUploadForm: {
		background: '#f4393c',
		width: 100,
		height: 100,
		borderRadius: 10,
		marginRight: 15,

		'& svg': {
			fontSize: 40,
		},

		'& input': {
			display: 'none'
		}
	},

	boxPreviewImg: {
		border: '2px dashed #ccc',
		minHeight: 100,
		minWidth: 100,
		maxWidth: 500,
		borderRadius: 10,
		padding: 20,
		overflow: 'hidden',
		margin: 5,

		'&.fixed': {
			height: '100px !important',
			width: '100px !important',
			padding: '5px !important',
		},

		'& img': {
			maxWidth: '100%',
			height: 'auto',
		}
	},

	imageBannerList: {
		width: 100,
		height: 75,

		'& img': {
			width: 'max-content',
			height: '100%',
		}
	},

	iconRating: {
		'&.accept': {
			'&.active': {
				color: 'green'
			}
		},

		'&.recused': {
			'&.active': {
				color: 'red'
			}
		}
	},

	path_image: {
		width: 180,
		height: 180,
	},

	boxFilter: {
		'& .inputSearch > .MuiOutlinedInput-root, .MuiAccordion-rounded:last-child ': {
			borderRadius: '15px !important',
			overflow: 'hidden',
		},

		'& .MuiAccordion-root:before': {
			display: 'none',
		},

		'& .MuiAccordionSummary-content > .MuiTypography-root': {
			display: 'flex',
			alignItems: 'center',
			fontSize: 20,

			'& svg': {
				marginRight: 20,
			}
		}
	}

}))

export default stylePagesManager