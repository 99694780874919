import { Box, Container } from '@material-ui/core'
import { Cancel, CheckCircle } from '@material-ui/icons'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { CustomButtom, IconCustom, TitleList } from '../../../Components/Customs'
import Manager from '../../../Components/Manager'
import api from '../../../Services/api'
import stylePagesManager from '../style'

const Ratings = ({ location }) => {

	const style = stylePagesManager()

	const [ratings, setRatings] = useState([])

	async function getRatings() {
		const res = await api.get('rating/listAll')
		setRatings(res.data)
	}

	async function handleClick(id, name, type, value) {
		const obj = {
			[type]: value
		}
		try {
			await api.put(`rating/${id}`, obj)

			if (value === 'accept') {
				toast.success(`${type.split('_')[1] === 'rh' ? 'RH' : 'Líder'} aprovou a avaliação de ${type.split('_')[2]} dias do funcionario ${name}`)
			} else {
				toast.warning(`${type.split('_')[1] === 'rh' ? 'RH' : 'Líder'} reprovou a avaliação de ${type.split('_')[2]} dias do funcionario ${name}`)
			}
		} catch (error) {
			toast.error('Falha ao enviar avaliação!')
		}

	}

	useEffect(() => {
		getRatings()
	}, [])

	return (
		<Manager>
			<Container maxWidth='xl'>
				<TitleList>Avaliações</TitleList>
				<Box display='flex' justifyContent='center'>
					<table className={`${style.table} center_header`} cellSpacing={0} cellPadding={0}>
						<thead>
							<tr>
								<th className={`title`} rowSpan={2}>Funcionário</th>
								<th className={`title`} colSpan={3}>45 Dias</th>
								<th className={`title`} colSpan={3}>90 Dias</th>
							</tr>
							<tr>
								<th className={`title`}>Data exp. 45</th>
								<th className={`title`}>OK RH</th>
								<th className={`title`}>OK Líder</th>
								<th className={`title`}>Data exp. 90</th>
								<th className={`title`}>OK RH</th>
								<th className={`title`}>OK Líder</th>
							</tr>
						</thead>

						<tbody>
							{ratings.map((row) => (
								<tr key={row.id}>
									<td>{row.name}</td>
									<td>{DateTime.fromSQL(row.date_45).toFormat('dd/MM/yyyy')}</td>
									<td>
										<Box display='flex' justifyContent='center'>
											<IconCustom onClick={e => handleClick(row.id_employee, row.name, 'ok_rh_45', 'accept')} className={`${style.iconRating} accept  ${row.ok_rh_45 === 'accept' && 'active'} `}><CheckCircle /></IconCustom>
											<IconCustom onClick={e => handleClick(row.id_employee, row.name, 'ok_rh_45', 'recused')} className={`${style.iconRating} recused ${row.ok_rh_45 === 'recused' && 'active'} `}><Cancel /></IconCustom>
										</Box>
									</td>
									<td>
										<Box display='flex' justifyContent='center'>
											<IconCustom onClick={e => handleClick(row.id_employee, row.name, 'ok_leader_45', 'accept')} data-type="" className={`${style.iconRating} accept  ${row.ok_leader_45 === 'accept' && 'active'} `}><CheckCircle /></IconCustom>
											<IconCustom onClick={e => handleClick(row.id_employee, row.name, 'ok_leader_45', 'recused')} className={`${style.iconRating} recused ${row.ok_leader_45 === 'recused' && 'active'} `}><Cancel /></IconCustom>
										</Box>									</td>
									<td>{DateTime.fromSQL(row.date_90).toFormat('dd/MM/yyyy')}</td>
									<td>
										<Box display='flex' justifyContent='center'>
											<IconCustom onClick={e => handleClick(row.id_employee, row.name, 'ok_rh_90', 'accept')} className={`${style.iconRating} accept  ${row.ok_rh_90 === 'accept' && 'active'} `}><CheckCircle /></IconCustom>
											<IconCustom onClick={e => handleClick(row.id_employee, row.name, 'ok_rh_90', 'recused')} className={`${style.iconRating} recused ${row.ok_rh_90 === 'recused' && 'active'} `}><Cancel /></IconCustom>
										</Box>
									</td>
									<td>
										<Box display='flex' justifyContent='center'>
											<IconCustom onClick={e => handleClick(row.id_employee, row.name, 'ok_leader_90', 'accept')} className={`${style.iconRating} accept ${row.ok_leader_90 === 'accept' && 'active'} `}><CheckCircle /></IconCustom>
											<IconCustom onClick={e => handleClick(row.id_employee, row.name, 'ok_leader_90', 'recused')} className={`${style.iconRating} recused ${row.ok_leader_90 === 'recused' && 'active'} `}><Cancel /></IconCustom>
										</Box>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</Box>
			</Container>
		</Manager>
	)
}

export default Ratings