const { makeStyles } = require("@material-ui/core");

const styleHeader = makeStyles((theme) => ({
	root: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'space-between',
		background: '#f4393c',
		height: 80,

		'& img': {
			maxWidth: '100%',
			height: 'auto',
		},

		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
			alignItems: 'center'
		}
	},

	logoHeader: {
		padding: 10,
		width: 150,
		height: '100%',
	},

	linkMenuDesk: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		textDecoration: 'none',
		color: '#fff',
		paddingLeft: 15,
		paddingRight: 15,

		'&:hover': {
			textDecoration: 'underline',
		},

		[theme.breakpoints.down('md')]: {
			paddingLeft: 10,
			paddingRight: 10,
			fontSize: 15,
		},

		[theme.breakpoints.up('lg')]: {
			fontSize: 18,
		}
	},

	iconMobile: {
		position: 'absolute',
		left: 0,
	},

	menuMobile: {

		[theme.breakpoints.down('sm')]: {
			width: '70vw',
		},

		[theme.breakpoints.down('xs')]: {
			width: '100vw',
		},

	},

	linkMenuMobile: {
		fontSize: 22,
		color: '#f4393c',
		textDecoration: 'none',
		marginTop: 15,
		marginBottom: 15,

		'&:hover': {
			textDecoration: 'underline',
		},
	},

	btnLogout: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',

		'& svg': {
			paddingLeft: 10,
			fontSize: 40
		}
	}

}))

export default styleHeader