import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux'

import { Box, Container, Drawer, Hidden } from '@material-ui/core';
import styleHeader from './style';
import { IconCustom } from '../Customs';
import { Close, Dehaze, ExitToApp } from '@material-ui/icons';
import { signOut } from '../../store/modules/auth/action';



const itemsMenu = [
	{
		url: '/destaques',
		name: 'Destaques',
	},

	{
		url: '/historia',
		name: 'História',
	},

	{
		url: '/missao-e-valores',
		name: 'Missão e Valores',
	},

	{
		url: '/codigo-de-conduta',
		name: 'Código de conduta',
	},

	{
		url: '/noticias',
		name: 'Últimas notícias',
	},

	{
		url: '/aniversariantes',
		name: 'Aniversáriantes',
	},
]

function Header() {

	const style = styleHeader()
	const dispatch = useDispatch()

	const [menuMobile, setMenuMobile] = useState(false)

	function handleMenuMobile() {
		setMenuMobile(!menuMobile)
	}

	function logout() {
		dispatch( signOut() )
	}

	return (
		<header className={style.root}>
			<Container maxWidth='xl' className={style.root}>
				<Box component='a' href="/destaques" className={style.logoHeader} display='flex' justifyContent='center' alignItems='center'>
					<img src="http://intranet.santolabs.com.br/static/media/intranet_logo.9ac52c76.png" alt="" />
				</Box>

				<Hidden smDown>
					<Box display='flex'>
						{itemsMenu.map(menu => (
							<Link key={menu.name} className={style.linkMenuDesk} to={menu.url}>{menu.name}</Link>
						))}

						<span onClick={logout} className={`${style.linkMenuDesk} ${style.btnLogout}`}> Sair <ExitToApp /> </span>
					</Box>
				</Hidden>

				<Hidden mdUp>
					<IconCustom className={style.iconMobile} onClick={handleMenuMobile} coloricon="#fff">
						<Dehaze />
					</IconCustom>
				</Hidden>

			</Container>

			<Drawer anchor="left" open={menuMobile} onClose={handleMenuMobile}>
				<Box className={style.menuMobile} >
					<Box display='flex' justifyContent='flex-end'>
						<IconCustom onClick={handleMenuMobile} coloricon="#f4393c">
							<Close />
						</IconCustom>
					</Box>
					<Box display='flex' alignItems="center" flexDirection="column">
						{itemsMenu.map(menu => (
							<Link key={menu.name} className={style.linkMenuMobile} to={menu.url}>{menu.name}</Link>
						))}

						<span className={`${style.linkMenuMobile} ${style.btnLogout}`} onClick={logout}> Sair <ExitToApp /> </span>
					</Box>
				</Box>
			</Drawer>
		</header>
	);
}

export default Header;