const { makeStyles } = require("@material-ui/core");

const styleSingleNotice = makeStyles((theme) => ({
	root: {
		'& .box_notice': {
			paddingTop: 50,
		},

		'& .image_notice': {
			'& > div': {
				overflow: 'hidden',

				[theme.breakpoints.up('md')]: {
					height: 500,

					'& img': {
						maxWidth: 'max-content',
						height: '100%',
					}
				},

				[theme.breakpoints.down('sm')]: {

					'& img': {
						maxWidth: '100%',
						height: 'auto',
					}
				},

			}
		},

		'& .gallery': {
			maxWidth: 600,
			width: '100%',
			height: 400,

			'& .slick-slide > div > div': {
				display: 'flex !important',
				justifyContent: 'center',
			},

			'& img': {
				maxHeight: 400,
			}
		}

	},

	joditEditor:{

			width: '100%',
			marginBottom: 30,

			'& iframe':{
				maxWidth: 790,
				width: '100%'
			},

		'& [data-jodit_iframe_wrapper]':{
			display: 'contents !important',

			'&:after':{
				position: 'relative',
			}
		}
	}
}))

export default styleSingleNotice
