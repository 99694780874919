import React, { useState } from 'react'

import { useDispatch } from 'react-redux'
import { DateTime } from 'luxon'

import { Container, Grid, InputLabel, OutlinedInput, Typography } from '@material-ui/core'
import stylePageLogin from './style'
import { CpfMask, DateBirthy } from '../../Components/Masks'
import { CustomButtom, InputCustom } from '../../Components/Customs'
import { signInRequest } from '../../store/modules/auth/action'

DateTime.local().setLocale('pt')

const Login = () => {

	const style = stylePageLogin()
	const dispatch = useDispatch()

	const [ data, setData ] = useState({
		cpf: '',
		date_birthy: '',
	})

	function handleValues(e){
		const { name, value } = e.target
		setData({ ...data, [name]: value })
	}

	async function signIn(e){
		e.preventDefault()
		const obj = {
			login: data.cpf.replace(/[^0-9]/gi, ""),
			password: DateTime.fromFormat(data.date_birthy, "dd/MM/yyyy").toFormat('yyyy-MM-dd')
		}
		dispatch(signInRequest(obj.login, obj.password))
	}

	return (
		<Container maxWidth={false} className={style.root}>

			<Grid container justify="center" alignItems="center">

				<Grid container item xs={12} sm={6} md={4} lg={3} className={style.boxLogin}>

					<Grid item xs={12} className={style.topMenu}>
						<img src="http://intranet.santolabs.com.br/static/media/intranet_logo.9ac52c76.png" alt="" />
					</Grid>

					<form onSubmit={signIn} className={style.formLogin}>

						<Grid container item xs={12}>

							<Grid item xs={12}>
								<Typography>Para acessar o conteúdo da empresa, digite seu CPF e data de aniversário.</Typography>
							</Grid>

							<Grid item xs={12} className={style.inputFormLogin}>
								<InputCustom variant="outlined" inputcolor='#f4393c' fullWidth>
									<InputLabel htmlFor="inputCpf">CPF</InputLabel>
									<OutlinedInput
										id="inputCpf"
										name="cpf"
										value={data.cpf}
										onChange={handleValues}
										label="CPF"
										type="tel"
										inputComponent={CpfMask}
									/>
								</InputCustom>
							</Grid>

							<Grid item xs={12} className={style.inputFormLogin}>
								<InputCustom variant="outlined" inputcolor='#f4393c' fullWidth>
									<InputLabel htmlFor="inputDateBirthy">Data de Nascimento</InputLabel>
									<OutlinedInput
										id="inputDateBirthy"
										name="date_birthy"
										value={data.date_birthy}
										onChange={handleValues}
										label="Data de Nascimento"
										type="tel"
										inputComponent={DateBirthy}
									/>
								</InputCustom>
							</Grid>

							<Grid item xs={12} className={style.btnSignIn}>
								<CustomButtom type="submit" size="large" fullWidth >entrar</CustomButtom>
							</Grid>

						</Grid>
					</form>

				</Grid>

			</Grid>

		</Container>
	)
}

export default Login