import { makeStyles } from '@material-ui/core'

const styleBanner = makeStyles((theme) => ({
	banner: {
		position: 'relative',

		'& img': {
			maxWidth: '100%',
			height: 'auto',
		},

		'&:hover': {
			'& .noticeTitle, .noticeReadMore': {
				textDecoration: 'underline',
			}
		}
	},

	slide: {

		'& $banner': {
			[theme.breakpoints.up('md')]: {
				height: 450,
			}
		},

		'& .box-single-img': {
			width: '100%',
			height: '100%',
			overflow: 'hidden',

			'& > a': {
				display: 'flex',
				justifyContent: 'center',
				alignItems: `center`,
				height: `100%`,
				width: '100%',
				overflow: `hidden`,
			},

			'& img': {
				[theme.breakpoints.up('md')]: {
					maxWidth: 'max-content !important',
					height: 'auto',
					minHeight: 450,
				},
				[theme.breakpoints.down('sm')]: {
					maxWidth: '100%',
					height: 'auto',
				},

			},
		},

		'& .slick-list': {
			height: '100%',
		},

		'& .slick-next': {
			right: 0,
		},

		'& .slick-prev': {
			left: 0,
		},

		'& .slick-dots': {
			display: 'none !important',
		},
	},

	slideArrow: {
		background: '#f4393c',
		color: '#fff',
		height: 50,
		width: 50,
		lineHeight: 0,
		position: 'absolute',
		top: '50%',
		display: 'flex',
		padding: 0,
		transform: 'translate(0, -50%)',
		cursor: 'pointer',
		border: 'none',
		outline: 'none',
		zIndex: 999,
		borderRadius: 5,
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 50,
	},

	slideNextArrow: {
		right: 15,
	},

	slidePrevArrow: {
		left: 15,
	},

	titleBannerSlide: {
		position: 'absolute',
		bottom: 0,
		background: '#282a31bf',
		color: '#fff',
		width: '100%',
		height: 150,
		padding: '10px 25px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',

		[theme.breakpoints.down('xs')]: {
			padding: 10,
			height: 105,
		},

		'& span': {

			'&:nth-child(1)': {

				[theme.breakpoints.up('xl')]: {
					fontSize: 30,
				},

				[theme.breakpoints.down('lg')]: {
					fontSize: '2vw',
				},

				[theme.breakpoints.down('md')]: {
					fontSize: '2.3vw',
				},

				[theme.breakpoints.down('sm')]: {
					fontSize: '3vw',
				},

				[theme.breakpoints.down('xs')]: {
					fontSize: '5vw',
				},
			},

			'&:nth-child(2)': {
				[theme.breakpoints.up('xl')]: {
					fontSize: 22,
				},

				[theme.breakpoints.down('lg')]: {
					fontSize: '1.2vw',
				},

				[theme.breakpoints.down('md')]: {
					fontSize: '1.4vw',
				},

				[theme.breakpoints.down('sm')]: {
					fontSize: '2vw',
				},

				[theme.breakpoints.down('xs')]: {
					fontSize: '4vw',
				},

			},

		}
	},

	noticeInfos: {
		padding: '20px 15px'
	}
}))

export default styleBanner