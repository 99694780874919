import React, { useEffect, useState } from 'react';

import { ContentManager, CustomButtom, IconCustom, TitleList } from '../../../Components/Customs';

import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Hidden, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@material-ui/core';
import { Add, ArrowBack, ArrowForward, ClearAll, Delete, Edit, Filter, FilterList, Search } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Manager from '../../../Components/Manager'
import stylePagesManager from '../style';
import api from '../../../Services/api';
import { toast } from 'react-toastify';

function Employee({ location }) {

	const style = stylePagesManager()

	const [search, setSearch] = useState('')
	const [orderBy, setOrderBy] = useState('')
	const [filters, setFilters] = useState({
		filters: [
			{
				type: '',
				condicional: '=',
				option: '',
				options: [],
				delete: false,
			}
		]
	})

	const [roles, setRoles] = useState([])
	const [sectors, setSectors] = useState([])
	const [departaments, setDepartaments] = useState([])

	const [employees, setEmployees] = useState({
		page: location.search ? location.search.split('=')[1] : 1,
		pages: 1,
		count: 0,
		limit: 10,
		rows: []
	})
	const [modalDelete, setModalDelete] = useState(false)
	const [id, setId] = useState(0)

	function handleFilter(e, index) {
		const { name, value } = e.target
		const arr = filters.filters
		arr[index][name] = value


		if (name === 'type') {
			switch (value) {
				case 'sector_id':
					arr[index]['options'] = sectors
					break;
				case 'departament_id':
					arr[index]['options'] = departaments
					break;
				case 'role_id':
					arr[index]['options'] = roles
					break;
				case 'gender':
					arr[index]['options'] = [
						{
							id: 'M',
							name: 'M'
						},
						{
							id: 'F',
							name: 'F'
						},
					]
					break;
				case 'is_active':
					arr[index]['options'] = [
						{
							id: 1,
							name: 'Ativo'
						},
						{
							id: 0,
							name: 'Inativo'
						}
					]
					break;
				case 'children':
					arr[index]['options'] = [
						{
							id: '> 0',
							name: 'Possui'
						},
						{
							id: '= 0',
							name: 'Não possui'
						}
					]
					break;
				case 'month_birth':
					arr[index]['options'] = [
						{
							id: 1,
							name: 'Janeiro'
						},
						{
							id: 2,
							name: 'Fevereiro'
						},
						{
							id: 3,
							name: 'Março'
						},
						{
							id: 4,
							name: 'Abril'
						},
						{
							id: 5,
							name: 'Maio'
						},
						{
							id: 6,
							name: 'Junho'
						},
						{
							id: 7,
							name: 'Julho'
						},
						{
							id: 8,
							name: 'Agosto'
						},
						{
							id: 9,
							name: 'Setembro'
						},
						{
							id: 10,
							name: 'Outubro'
						},
						{
							id: 11,
							name: 'Novembro'
						},
						{
							id: 12,
							name: 'Dezembro'
						},

					]
					break;
			}
		}

		setFilters({ filters: arr })
	}

	function addFilter() {
		const arr = filters.filters
		arr.push({
			type: '',
			condicional: '=',
			option: '',
			options: [],
			delete: true,
		})
		setFilters({ filters: arr })
	}

	function deleteFilter(i) {
		const arr = filters.filters
		arr.splice(i, 1)
		setFilters({ filters: arr })
	}

	function resetFilter() {
		setFilters({
			filters: [
				{
					type: '',
					condicional: '=',
					option: '',
					options: [],
					delete: false,
				}
			]
		})

		getEmployees()
	}

	function handleModalDelete(id) {
		setId(id)
		setModalDelete(true)
	}

	function handleClose() {
		setId(0)
		setModalDelete(false)
	}

	async function getEmployees(filter = false) {
		if (filter) {
			console.log('filter')
			const res = await api.post(`employee/employeeFilter`, filter)
			console.log(res)
			setEmployees({
				page: 1,
				pages: 1,
				limit: 10,
				count: 0,
				rows: res.data
			})
		} else {
			if (search) {
				console.log('search')
				const res = await api.post(`employee/searchFilter`, { search: search })
				setEmployees({
					page: 1,
					pages: 1,
					limit: 10,
					count: 0,
					rows: res.data
				})
			} else {
				console.log('normal')
				const res = await api.get(`employee/?page=${employees.page}`)
				setEmployees({
					...employees,
					pages: res.data.pages,
					count: res.data.count,
					limit: res.data.limit,
					rows: res.data.employee,
				})
			}
		}
	}

	async function getRoles() {
		const res = await api.get('role/listAll')
		setRoles(res.data.rows)
	}

	async function getSectors() {
		const res = await api.get('sector/listAll')
		setSectors(res.data.rows)
	}

	async function getDepartaments() {
		const res = await api.get(`departament/listAll`)
		setDepartaments(res.data)
	}

	function handleSearch(e) {
		setSearch(e.target.value)
	}

	async function deleteEmployee(id) {
		const res = await api.delete(`employee/${id}`)
		console.log(res)
	}

	function getFilters() {
		let str = ''
		const obj = {
			query: '',
			order: ''
		}
		filters.filters.map(f => {
			if (f.type) {
				if (f.type === 'children') {
					str += `e.${f.type} ${f.option} and `
				} else if (f.type === 'month_birth') {
					str += `MONTH(e.date_birth) = ${f.option} and `
				} else {
					str += `e.${f.type} ${f.condicional} '${f.option}' and `
				}
			}
		})
		obj.query = str.substring(0, (str.length - 4))

		console.log(obj)
		getEmployees(obj)
	}

	function handleModalDelete(id) {
		setId(id)
		setModalDelete(true)
	}

	function handleClose() {
		setId(0)
		setModalDelete(false)
	}

	async function deleteEmployee() {
		try {
			await api.delete(`employee/${id}`)
			toast.success('Funcionário deletada com sucesso')
			resetPage()
		} catch (error) {
			toast.error('Falha ao deletar funcionário')
		}

		handleClose()
	}

	function resetPage() {
		setSearch('')
		setFilters({
			filters: [
				{
					type: '',
					condicional: '=',
					option: '',
					options: [],
					delete: false,
				}
			]
		})

		getEmployees()
	}

	useEffect(() => {
		if(search.length > 3 || search.length === 0){
			getEmployees()
		}
	}, [search])

	useEffect(() => {
		getEmployees()
		getRoles()
		getSectors()
		getDepartaments()
	}, [])

	return (
		<>
			<Manager>
				<Container maxWidth="xl" className={style.root}>
					<Box display='flex' justifyContent='center'>
						<ContentManager width="100%">
							<Box className={style.boxFilter}>
								<FormControl variant='outlined' fullWidth className='inputSearch'>
									<OutlinedInput
										placeholder='Digite o que você procura ...'
										value={search}
										onChange={handleSearch}
										startAdornment={
											<InputAdornment>
												<Search />
											</InputAdornment>
										}
									/>
								</FormControl>
								<Typography align='center'>ou</Typography>
								<Accordion className={style.boxFilter}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="filters"
										id="filters"
									>

										<Typography> <FilterList /> Filtros</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Grid container spacing={2}>
											{filters.filters.map((f, i) => (
												<Grid key={i} container item xs={12} spacing={2}>
													<Grid item md={4}>
														<FormControl variant='outlined' fullWidth>
															<InputLabel htmlFor='type'>Filtros</InputLabel>
															<Select
																name='type'
																value={f.type}
																id='type'
																label='Filtros'
																onChange={e => handleFilter(e, i)}
															>
																<MenuItem disabled >Selecione um filtro</MenuItem>
																<MenuItem value='sector_id' >Setor</MenuItem>
																<MenuItem value='departament_id' >Departamento</MenuItem>
																<MenuItem value='role_id' >Cargo</MenuItem>
																<MenuItem value='is_active' >Status</MenuItem>
																<MenuItem value='children' >Filhos</MenuItem>
																<MenuItem value='gender' >Sexo</MenuItem>
																<MenuItem value='month_birth' >Mês de Nascimento</MenuItem>
															</Select>
														</FormControl>
													</Grid>
													<Grid item md={2}>
														<FormControl variant='outlined' fullWidth>
															<InputLabel htmlFor='conditional'>Condicional</InputLabel>
															<Select
																name='condicional'
																value={f.condicional}
																id='condicional'
																label='Condicional'
																onChange={e => handleFilter(e, i)}
															>
																<MenuItem value='='>Igual a</MenuItem>
																<MenuItem value='!='>Diferente de</MenuItem>
															</Select>
														</FormControl>
													</Grid>
													<Grid item md={f.delete ? 5 : 6}>
														<FormControl variant='outlined' fullWidth>
															<InputLabel htmlFor='option'>Opções</InputLabel>
															<Select
																name='option'
																value={f.option}
																id='option'
																label='Opções'
																onChange={e => handleFilter(e, i)}
															>
																{f.options.map(o => (
																	<MenuItem key={o.id} value={o.id}> {o.name ? o.name : o.departament_name ? o.departament_name + ' - ' + o.sector_name : ''}</MenuItem>
																))}
															</Select>
														</FormControl>
													</Grid>

													{f.delete &&
														<Grid item md={1}>
															<Box display='flex' justifyContent='center'>
																<IconCustom coloricon='red' onClick={e => deleteFilter(i)}>
																	<Delete />
																</IconCustom>
															</Box>
														</Grid>

													}
												</Grid>
											))}

											<Grid item xs={12}>
												<Box display='flex' justifyContent='space-between'>
													<Box>
														<CustomButtom
															onClick={resetFilter}
															startIcon={<ClearAll />}
															style={{ marginRight: 15 }}
															bg='orange'
														>
															limpar filtros
														</CustomButtom>

														<CustomButtom
															onClick={addFilter}
															startIcon={<Add />}
															bg="#58bf58"
														>
															Adicionar filtro
														</CustomButtom>
													</Box>
													<CustomButtom
														onClick={getFilters}
													>
														filtrar
													</CustomButtom>

												</Box>
											</Grid>
										</Grid>
									</AccordionDetails>
								</Accordion>
							</Box>

							<Box display='flex' justifyContent='space-between' alignItems='center'>
								<TitleList>Funcionários</TitleList>

								<CustomButtom
									size='large'
									bg='#f4393c'
									href='/admin/funcionario/novo'
									startIcon={<Add />}
									className={style.btnAdd}
								>
									Adicionar
							</CustomButtom>
							</Box>
							<Box display='flex' justifyContent='center'>
								<table className={style.table} cellSpacing={0} cellPadding={0}>
									<thead>
										<tr>
											<Hidden smDown>
												<th className={`title`}>Foto</th>
											</Hidden>
											<th className={`title`}>Nome</th>
											<th className={`title`}>Setor</th>
											<th className={`title`}>Departamento</th>
											<th className={`title settings`}>Opções</th>
										</tr>
									</thead>

									<tbody>
										{employees.rows.map((row) => (
											<tr key={row.id}>
												<td>
													<Avatar src={row.path_image} alt={`Foto funcionário ${row.name}`} />
												</td>
												<td>{row.name} {row.lastname}</td>
												<td>{row.name_sector}</td>
												<td>{row.name_departament}</td>
												<td className='settings'>
													<IconCustom coloricon="orange" href={`/admin/funcionario/editar/${row.id}`}>
														<Edit />
													</IconCustom>

													<IconCustom coloricon="red" onClick={e => handleModalDelete(row.id)}>
														<Delete />
													</IconCustom>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</Box>
							<Box display='flex' justifyContent={employees.page > 1 ? 'space-between' : 'flex-end'}>
								{employees.page > 1 && <CustomButtom startIcon={<ArrowBack />} href={`/admin/funcionarios?pagina=${parseInt(employees.page) - 1}`}> anterior </CustomButtom>}
								{employees.page < employees.pages && <CustomButtom endIcon={<ArrowForward />} href={`/admin/funcionarios?pagina=${parseInt(employees.page) + 1}`}> próximo </CustomButtom>}
							</Box>
						</ContentManager>
					</Box>
				</Container>
			</Manager>

			<Dialog open={modalDelete} onClose={handleClose} aria-labelledby='form-employee' className={style.modalForm}>
				<DialogTitle id='form-employee'>Deletar Funcionário</DialogTitle>
				<DialogContent>
					<Typography className='content_modal_delete'>Deseja realmente deletar o funcionário </Typography>
				</DialogContent>
				<DialogActions>
					<CustomButtom
						bg="orange"
						startIcon={<ArrowBack />}
						onClick={handleClose}
					>
						voltar
						</CustomButtom>

					<CustomButtom
						bg="red"
						endIcon={<Delete />}
						onClick={deleteEmployee}
					>
						deletar
						</CustomButtom>
				</DialogActions>
			</Dialog>

		</>
	)
}

export default Employee;