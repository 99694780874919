import { makeStyles } from '@material-ui/core'

const stylePageNotices = makeStyles((theme) => ({
	root: {

	},

	boxPost: {
		overflow: 'hidden',
		height: 400,
		background: '#fff',
		boxShadow: '-1px 1px 8px -5px #00000082',
		borderRadius: 10,
		textDecoration: 'none',

		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
			height: 500,
		},

		'&:hover .read-more': {
			textDecoration: 'underline'
		}
	},

	thumbPost: {
		overflow: 'hidden',
		width: '50%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		'& img': {
			[theme.breakpoints.down('sm')]: {
				maxWidth: '100%',
				height: 'auto',
			},

			[theme.breakpoints.up('md')]: {
				maxWidth: 'max-content',
				height: '100%',
			}
		},

		[theme.breakpoints.down('xs')]: {
			width: '100%',
			height: 'auto',
		}
	},

	contentPost: {
		width: '50%',
		padding: '20px 15px',
		color: '#271e1e',

		'& .title': {
			color: '#282a31',
			fontSize: 28,
			paddingBottom: 20,
			overflow: 'hidden',

			[theme.breakpoints.up('lg')]: {
				fontSize: 24,
				maxHeight: 130,
			},

			[theme.breakpoints.down('sm')]: {
				fontSize: '3vw'
			},

			[theme.breakpoints.down('xs')]: {
				fontSize: 26,
				height: 125,
			},

		},

		'& .resume': {
			color: '#271e1e',
			fontSize: 20,
			overflow: 'hidden',

			[theme.breakpoints.up('lg')]: {
				fontSize: 16,
				maxHeight: 150,
			},

			[theme.breakpoints.down('sm')]: {
				fontSize: 14,
				maxHeight: 130,
				overflow: 'hidden',
			},

			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
				height: 100,
				marginBottom: 30,
			},
		},

		'& .read-more': {
			color: '#f4393c'
		},

		[theme.breakpoints.down('xs')]: {
			width: '100%',
		}

	}
}))

export default stylePageNotices