const { makeStyles } = require("@material-ui/core");

const styleModalHistory = makeStyles( (theme) => ({
	root: {

	},

	formHistory: {
		width: 500 ,
	}
}))

export default styleModalHistory