import { makeStyles } from '@material-ui/core'

const stylePageMission = makeStyles((theme) => ({
	root: {

	},

	boxImgValues: {
		'& $imgValues':{
			'&:nth-child(1)': {
				transform: 'rotate(-5deg)',
				overflow: 'hidden',
				borderTopLeftRadius: 40,
				borderTopRightRadius: 20,
				borderBottomLeftRadius: 20,
				borderBottomRightRadius: 40,

				'& > div': {
					transform: 'rotate(5deg)',
				}
			},

			'&:nth-child(2)': {
				transform: 'rotate(2deg)',
				overflow: 'hidden',
				borderTopLeftRadius: 20,
				borderTopRightRadius: 20,
				borderBottomLeftRadius: 40,
				borderBottomRightRadius: 20,

				'& > div': {
					transform: 'rotate(-3deg)',
				}
			}, 

			'&:nth-child(3)': {
				transform: 'rotate(-2deg)',
				overflow: 'hidden',
				borderTopLeftRadius: 40,
				borderTopRightRadius: 20,
				borderBottomLeftRadius: 20,
				borderBottomRightRadius: 20,

				'& > div': {
					transform: 'rotate(2deg)',
				}
			}, 

			'&:nth-child(4)': {
				transform: 'rotate(0deg)',
				overflow: 'hidden',
				borderTopLeftRadius: 40,
				borderTopRightRadius: 20,
				borderBottomLeftRadius: 20,
				borderBottomRightRadius: 40,

				'& > div': {
					transform: 'rotate(2deg)',
				}
			},

			'&:nth-child(5)': {
				transform: 'rotate(3deg)',
				overflow: 'hidden',
				borderTopLeftRadius: 40,
				borderTopRightRadius: 40,
				borderBottomLeftRadius: 40,
				borderBottomRightRadius: 20,

				'& > div': {
					transform: 'rotate(-4deg)',
				}
			},

			'&:nth-child(6)': {
				transform: 'rotate(-1deg)',
				overflow: 'hidden',
				borderTopLeftRadius: 20,
				borderTopRightRadius: 20,
				borderBottomLeftRadius: 20,
				borderBottomRightRadius: 20,

				'& > div': {
					transform: 'rotate(1deg)',
				}
			},

			'&:nth-child(7)': {
				transform: 'rotate(2deg)',
				overflow: 'hidden',
				borderTopLeftRadius: 40,
				borderTopRightRadius: 20,
				borderBottomLeftRadius: 20,
				borderBottomRightRadius: 40,

				'& > div': {
					transform: 'rotate(-2deg)',
				}
			},

			'&:nth-child(8)': {
				transform: 'rotate(-4deg)',
				overflow: 'hidden',
				borderTopLeftRadius: 40,
				borderTopRightRadius: 20,
				borderBottomLeftRadius: 20,
				borderBottomRightRadius: 40,

				'& > div': {
					transform: 'rotate(4deg)',
				}
			},

		}
	},

	imgValues: {
		background: '#fff',
		border: '1px solid #b01010',
		margin: '20px 0px',

		[theme.breakpoints.down('xs')]: {
			width: '45%'
		},
		
		'& div': {
			width: 200,
			height: 280,

			[theme.breakpoints.down('xs')]: {
				width: '100%',
				height: '55vw',
			}
			
		},

		'& img': {
			maxWidth: '100%',
			height: '100%',
		}
	},

	content: {
		'&, *': {
			fontSize: 18,
		},

		'& .MuiTypography-root': {
			margin: '15px 0',

			'& b': {
				fontSize: 20,
			}
		}
	}

}))

export default stylePageMission