import React, { useEffect } from 'react';

import Header from '../../Components/Header';

import { Box, Container, Typography } from '@material-ui/core';
import stylePageCodeConduct from './style';
import { TitlePage } from '../../Components/Customs';
import { Link } from 'react-router-dom';
import Footer from '../../Components/Footer';

import ReactGa from 'react-ga'

function CodeConduct() {

	useEffect(() => {
		ReactGa.pageview('/codigo-de-conduta')
	}, [])

	const style = stylePageCodeConduct()

	return (
		<>
			<Header />
			<Container maxWidth="md" className={style.root}>

				<TitlePage>Código de Conduta</TitlePage>
				<Box className={style.content}>
					<ul>
						<li>
							<Typography>
								Mantenha o seu local de trabalho sempre em ordem e limpo.
							</Typography>
						</li>
						<li>
							<Typography>
								Ocorrendo a quebra de equipamentos da empresa, informe imediatamente
								ao superior imediato.
							</Typography>
						</li>
						<li>
							<Typography>
								Tenha o hábito de pegar qualquer lixo que esteja no chão e colocá-lo
								no cesto de lixo.
							</Typography>
						</li>
						<li>
							<Typography>
								Guarde adequadamente os materias na área reservada.
							</Typography>
						</li>
						<li>
							<Typography>
								Seja sempre educado e simpático com os colegas de trabalho, cliente e
								fornecedores. Eles são nosso maior bem.
							</Typography>
						</li>
						<li>
							<Typography>
								Durante o expediente, esteja sempre vestido adequadamente para locomover-se
								pelas dependências da empresa.
							</Typography>
						</li>
						<li>
							<Typography>
								A empresa não se responsabiliza por quaisquer objetos e/ou valores particulares
								deixados em suas dependências.
							</Typography>
						</li>
						<li>
							<Typography>
								Não deixe seus pertences fora do armário.
							</Typography>
						</li>
						<li>
							<Typography>
								Os banheiros deve ser mantidos limpos e organizados, não é permitido deixar nada
								jogado ou pendurado. O que for encontrado no banheiro (sapato, roupas, inclusive uniforme) será
								enviado para doação.
							</Typography>
						</li>
						<li>
							<Typography>
								As áreas em comum devem ser mantidas sempre em ordem.
							</Typography>
						</li>
						<li>
							<Typography>
								É extremamente proibido o uso de celulares, mp3 ou equipamentos relacionados, no horário de trabalho,
								sendo permitido somente em horário de almoço, de forma que não atrapalhe os colegas de trabalho.
							</Typography>
						</li>
					</ul>
				</Box>

				<TitlePage>Respeito entre os colaboradores</TitlePage>

				<Box className={style.content}>
					<Typography>
						<b>A Responsabilidade de proporcionar um ambiente de trabalho agradável É DE TODOS.</b>
					</Typography>

					<ul>
						<li>
							<Typography>
								Não é permitido qualquer tipo de discriminação em função de: cor, opção sexual, idade, religião,
								raça, nacionalidade ou tendências políticas.
							</Typography>
						</li>

						<li>
							<Typography>
								O tratamento entre todos deve sempre ser educado, sem palavras de baixo calão ou gestos ofensivos.
								Gentileza gera gentileza.
							</Typography>
						</li>

						<li>
							<Typography>
								Nunca mexa ou pegue objetos de outros sem autorização do próprio.
							</Typography>
						</li>

						<li>
							<Typography>
								Sempre se refira ao colega de trabalho pelo nome, evite o uso de apelidos.
							</Typography>
						</li>

						<li>
							<Typography>
								Ao telefone, sempre se identifique antes de tranferir uma ligação.
							</Typography>
						</li>
					</ul>
				</Box>

				<TitlePage>Segurança da informação</TitlePage>

				<Box className={style.content}>
					<Typography>
						<b>è um conjunto de medidas que visa proteger e preservar informações e sistemas da empresa.</b>
					</Typography>

					<ul>
						<li>
							<Typography>
								Utilize apenas informações que são necessária para a sua função.
							</Typography>
						</li>

						<li>
							<Typography>
								Não divulgue ao publique as informações corporativas que não forem autorizadas pela Diretoria.
							</Typography>
						</li>

						<li>
							<Typography>
								Informações em papel devem ser arquivadas sempre de maneira segura em gavetas ou armários destinados.
							</Typography>
						</li>
					</ul>
				</Box>

				<TitlePage>Senhas</TitlePage>

				<Box className={style.content}>
					<ul>
						<li>
							<Typography>
								Sua senha é pessoal e intranferível.
							</Typography>
						</li>

						<li>
							<Typography>
								Não divulgue ou compartilhe.
							</Typography>
						</li>

						<li>
							<Typography>
								Nunca a deixe anotada em papéis soltos pelos espaços de trabalho.
							</Typography>
						</li>
					</ul>
				</Box>

				<TitlePage>E-Mails</TitlePage>

				<Box className={style.content}>
					<ul>
						<li>
							<Typography>
								Uso restritos para assuntos profissionais.
							</Typography>
						</li>

						<li>
							<Typography>
								Não abra links ou anexos sem a certeza de conhecer o remetente.
							</Typography>
						</li>

					</ul>
				</Box>

				<TitlePage>Internet</TitlePage>

				<Box className={style.content}>
					<ul>
						<li>
							<Typography>
								Deve ser utilizado estritamente para uso profissional e com
								a ciência de que a rede é monitorada.
							</Typography>
						</li>
					</ul>

					<Box className={style.boxObs}>
						<Typography><b>É extremamente proibido:</b></Typography>
						<Typography>
							Acessar conteúdos com pornografia, obscenidades, discriminação racial, política,
							religiosa e terrorismo, bem como salas de bate-papo, jogos, músicas e filmes:
							<b> passível de penalização.</b>
						</Typography>
					</Box>
				</Box>

				<TitlePage>Manual de Integração</TitlePage>

				<Box className={style.content}>
					<Typography>Clique <Link to='/manual-de-integracao' target="_blank" style={{ color: '#f4393c' }} >aqui</Link> e veja o manual de integração completo.</Typography>
				</Box>

			</Container>

			<Footer />
		</>
	)
}

export default CodeConduct;