import React, { useEffect, useState } from 'react';
import api from '../../../Services/api'

import { Box, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Hidden, InputLabel, OutlinedInput, Typography } from '@material-ui/core';

import Manager from '../../../Components/Manager';
import { CustomButtom, TitleList, ContentManager, IconCustom } from '../../../Components/Customs';
import { Add, ArrowBack, Delete, Done, Edit } from '@material-ui/icons';
import { toast } from 'react-toastify';
import stylePagesManager from '../style';


function Sectors() {

	const style = stylePagesManager()

	const [rows, setRows] = useState([])
	const [data, setData] = useState({
		id: 0,
		name: ''
	})
	const [modal, setModal] = useState(false)
	const [modalDelete, setModalDelete] = useState(false)

	async function getSectors() {
		const res = await api.get('sector/listAll')
		setRows(res.data.rows)
	}

	function handleModal(data) {
		setData({
			id: data.id,
			name: data.name
		})
		setModal(true)
	}

	function handleModalDelete(data) {
		setData({
			id: data.id,
			name: data.name
		})
		setModalDelete(true)
	}

	function handleClose() {
		setData({
			id: 0,
			name: ''
		})
		setModal(false)
		setModalDelete(false)
	}

	function handleValues(e) {
		const { name, value } = e.target
		setData({ ...data, [name]: value })
	}

	async function deleteSector() {
		try {
			const res = await api.delete(`sector/${data.id}`)
			console.log(res)
			toast.success('Setor deletado com sucesso')
			getSectors()
		} catch (error) {
			toast.error('Falha ao deletar setor')
		}

		handleClose()
	}

	async function envSector(e) {
		e.preventDefault()
		try {
			const config = {
				type: 'put',
				url: `sector/${data.id}`
			}

			if (!data.id) {
				delete data.id
				config.type = 'post'
				config.url = 'sector'
			}

			await api[config.type](config.url, data)

			getSectors()

			toast.success(`Setor ${data.id ? 'atualizado' : 'cadastrado'} com sucesso!`)

			handleClose()

		} catch (error) {
			toast.error(`Falha ao ${data.id ? 'atualizar' : 'cadastrar'} setor!`)
		}

	}

	useEffect(() => {
		getSectors()
	}, [])

	return (
		<>
			<Manager>
				<Container maxWidth="xl" className={style.root}>
					<Box display='flex' justifyContent='center'>
						<ContentManager width={'70%'}>
							<Box display='flex' justifyContent='space-between' alignItems='center'>
								<TitleList>Setores</TitleList>

								<CustomButtom
									size='large'
									bg='#f4393c'
									startIcon={<Add />}
									onClick={handleModal}
									className={style.btnAdd}
								>
									Adicionar Setor
						</CustomButtom>
							</Box>
							<Box display='flex' justifyContent='center'>
								<table className={style.table} cellSpacing={0} cellPadding={0}>
									<thead>
										<tr>
											<Hidden smDown>
												<th className={`title`}>ID</th>
											</Hidden>
											<th className={`title`}>Nome do setor</th>
											<th className={`title settings`}>Opções</th>
										</tr>
									</thead>

									<tbody>
										{rows.map((row) => (
											<tr key={row.id}>
												<Hidden smDown>
													<td>{row.id}</td>
												</Hidden>

												<td>{row.name}</td>
												<td className='settings'>
													<IconCustom coloricon="orange" onClick={e => handleModal(row)}>
														<Edit />
													</IconCustom>

													<IconCustom coloricon="red" onClick={e => handleModalDelete(row)} >
														<Delete />
													</IconCustom>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</Box>
						</ContentManager>
					</Box>
				</Container>
			</Manager>

			<Dialog open={modal} onClose={handleClose} aria-labelledby='form-sector' className={style.modalForm}>
				<DialogTitle id='form-sector'>{data.id ? 'Editar' : 'Cadastrar'} Setor</DialogTitle>
				<form onSubmit={envSector}>
					<DialogContent>

						<FormControl variant="outlined" fullWidth>
							<InputLabel>Nome do setor</InputLabel>
							<OutlinedInput
								name="name"
								onChange={handleValues}
								defaultValue={data.name}
								label="Nome do setor"
							/>
						</FormControl>

					</DialogContent>
					<DialogActions>
						<CustomButtom
							startIcon={<ArrowBack />}
							onClick={handleClose}
						>
							voltar
						</CustomButtom>

						<CustomButtom
							bg="green"
							endIcon={<Done />}
							type='submit'
						>
							{data.id ? 'salvar' : 'cadastrar'}
						</CustomButtom>
					</DialogActions>
				</form>
			</Dialog>

			<Dialog open={modalDelete} onClose={handleClose} aria-labelledby='form-dpto' className={style.modalForm}>
				<DialogTitle id='form-dpto'>Deletar Setor</DialogTitle>
				<DialogContent>
					<Typography className='content_modal_delete'>Deseja realmente deletar o setor {data.name} </Typography>
				</DialogContent>
				<DialogActions>
					<CustomButtom
						bg="orange"
						startIcon={<ArrowBack />}
						onClick={handleClose}
					>
						voltar
						</CustomButtom>

					<CustomButtom
						bg="red"
						endIcon={<Delete />}
						onClick={deleteSector}
					>
						deletar
						</CustomButtom>
				</DialogActions>
			</Dialog>

		</>
	)
}

export default Sectors;