import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import history from '../../Services/history';

import auth from './auth/reducer';
import user from './user/reducer';

export default combineReducers({
  router: connectRouter(history),
  auth,
  user
});