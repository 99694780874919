import { Box, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';

import ReactGa from 'react-ga'


const useStyle = makeStyles(() => ({
	root: {
		width: '100vw',
		height: '100vh',

		'& iframe': {
			width: '100%',
			height: '100%',
		}
	}
}))

function Manual() {

	useEffect(() => {
		ReactGa.pageview('/manual-de-integração')
	}, [])

	const style = useStyle()

	return (
		<Box className={style.root}>
			<iframe src="https://cdn.flipsnack.com/widget/v2/widget.html?hash=vc96mimzhm" width="100%" height="480" seamless="seamless" scrolling="no" frameBorder="0" allowFullScreen></iframe>
		</Box>
	);
}

export default Manual;