import { all, takeLatest, call, put } from 'redux-saga/effects';
import { signInSuccess, signFailure } from './action';
import api from '../../../Services/api';
import history from '../../../Services/history';
import { toast } from "react-toastify";
import { push } from 'connected-react-router';

export function* signIn({ payload }) {
  try {
    const obj = {
      login: payload.login,
      password: payload.password
    }
    const res = yield call(api.post, 'user/authenticate', obj);
    const { token, user : { id, login, type_user } } = res.data;
    yield put(signInSuccess(token, { id, login, type_user  } ));
    
    if(token) {
      api.defaults.headers.Authorization = `Bearer ${token}`
    }

    toast.success(`Seja bem vindo`)

    console.log(history.location)

    if(history.location.state !== undefined){
      return yield put(push(history.location.state.from.pathname))
    }
    
    if(type_user === 'user'){
      yield put(push('/destaques'))
    }

    if(type_user === 'admin'){
      yield put(push('/admin'))
    }
    
  } catch (err) {
    yield put(signFailure())
    toast.error('Usuário ou Senha inválido.')
  }
}

export function setToken({ payload }){
  if(!payload) return;
  const { token } = payload.auth;
  if(token) {
    api.defaults.headers.Authorization = `Bearer ${token}`
  }
}

export function* signOut(){
  yield put(push('/'))
}

export function signInExpired(page){
  history.push({ pathname: '/', state: { from: page } })
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_OUT', signOut),
  takeLatest('@auth/SIGN_IN_EXPIRED', signInExpired),
]);
