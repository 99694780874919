import { withStyles, Button, IconButton, Typography, FormControl, Box } from '@material-ui/core'
import styled from 'styled-components'

const CustomButtom = withStyles((theme) => ({
	root: {
		background: props => props.bg ? props.bg : '#f4393c',
		color: props => props.color ? props.color : '#fff',
		maxHeight: 45,

		'&:hover': {
			background: props => props.hover ? props.hover : props.bg ? props.bg : '#e01d20'
		}
	}
}))(Button)


const IconCustom = withStyles((theme) => ({
	root: {
		color: props => props.coloricon ? props.coloricon : '#000',
	}
}))(IconButton)

const TitlePage = withStyles((theme) => ({
 root: {
	color: '#f4393c',
	fontSize: 40,
	paddingTop: 20,
	paddingBottom: 15,
	fontWeight: 'bold',

	[theme.breakpoints.down('xs')]:{
		fontSize: 22,
	}
 }
}))(Typography)

const InputCustom = withStyles((theme) => ({
	root: {
		'& label.Mui-focused': {
			color: props => props.inputcolor ? props.inputcolor : '#271e1e'
		},
		
		'& .MuiOutlinedInput-root.Mui-focused': {
			'& fieldset': {
				borderColor: props => props.inputcolor ? props.inputcolor : '#271e1e',
			}
		},


	}
 }))(FormControl)

 const ContentManager = withStyles((theme) => ({
	root: {
		[theme.breakpoints.down('md')]:{
			width: '100%'
		}
	}
 }))(Box)



 const TitleList = withStyles((theme) => ({
	root: {
		color: '#f4393c',
		fontWeight: 'bold',
		marginBottom: 20,
		marginTop: 15,
		fontSize: 28,
		
		[theme.breakpoints.down('xs')]: {
			fontSize: '5vw',
		},

	}
 }))(Typography)


export { CustomButtom, IconCustom, TitlePage, InputCustom, ContentManager, TitleList }