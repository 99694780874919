import { css } from 'styled-components'
import LatoRegular from './lato/Lato-Regular.ttf'

const mainFonts = css`
	@font-face{
		font-family: 'lato-regular', sans-serif;
    font-weight: 400;
    src: url('${LatoRegular}');
	}
`;

export default mainFonts