import {
  FormControl,
  FormControlLabel,
  Typography,
  RadioGroup,
  Radio,
  ThemeProvider,
} from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import React from "react";
import { useState, useEffect } from "react";
import styledBallotBox from "./style";
import { toast } from "react-toastify";
import history from "../../Services/history";
import api from "../../Services/api";

export function BallotBox() {
  const votationTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#FF1744",
      },
    },
  });

  const btnText = "Próximo >";

  const style = styledBallotBox();

  const [divClass, setDivClass] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [divClass]);

  const [firstVote, setFirstVote] = useState("");
  const [secondVote, setSecondVote] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();

    const body = {
      vote_one: firstVote,
      vote_two: secondVote,
    };
    try {
      await api.post(`vote`, body);
      toast.success("Votos enviados com sucesso!");
      redirect();
    } catch (error) {
      console.log(error.response.data);
      console.log(error);

      toast.warning("Ops parece que você já votou!");
    }
  }

  function redirect() {
    history.push("https://intranet.santolabs.com.br/");
  }

  const votes1 = [1, 2, 3, 4];

  const votes2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormControl component="fieldset" className={style.formControl}>
          <div className={divClass ? `${style.secondCategoryOut}` : ""}>
            <Typography
              variant="h6"
              component="h6"
              className={style.contestTitle}
            >
              Categoria: de 2 à 6 anos
            </Typography>

            <p className={style.p}>
              Tema: Como seria o seu mundo doce? <br />
              Escolha o desenho que na sua opinião foi o mais criativo:
            </p>

            <ThemeProvider theme={votationTheme}>
              <RadioGroup className={style.radioGroup}>
                {votes1.map((elements) => (
                  <FormControlLabel
                    key={`voto_1_crianca_${elements}.jpg`}
                    className={style.radio}
                    value={`voto_1_crianca_${elements}.jpg`}
                    name={`voto_1_crianca_${elements}.jpg`}
                    alt={`voto_1_crianca_${elements}.jpg`}
                    control={
                      <Radio
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                      />
                    }
                    required
                    onChange={() =>
                      setFirstVote(`voto_1_crianca_${elements}.jpg`)
                    }
                    label={
                      <>
                        <div className={style.divImg}>
                          <img
                            src={`https://intranet.santolabs.com.br/uploads/voto_1_crianca_${elements}.jpg`}
                            alt="desenho"

                          ></img>
                        </div>
                      </>
                    }
                  />
                ))}
              </RadioGroup>
            </ThemeProvider>

            <div className={style.divBtn}>
              <button
                type="button"
                disabled={firstVote ? false : true}
                onClick={() => setDivClass(true)}
                className={style.button}
              >
                {btnText}
              </button>
            </div>
          </div>

          <div className={divClass ? "" : `${style.secondCategoryOut}`}>
            <Typography
              variant="h6"
              component="h6"
              className={style.contestTitle}
            >
              Categoria: de 7 à 14 anos
            </Typography>

            <p className={style.p}>
              Tema: Como seria o seu mundo doce? <br />
              Escolha o desenho que na sua opinião foi o mais criativo:
            </p>

            <ThemeProvider theme={votationTheme}>
              <RadioGroup className={style.radioGroup}>
                {votes2.map((elements) => (
                  <FormControlLabel
                    key={`voto_2_crianca_${elements}.jpg`}
                    className={style.radio}
                    value={`voto_2_crianca_${elements}.jpg`}
                    name={`voto_2_crianca_${elements}.jpg`}
                    alt={`voto_2_crianca_${elements}.jpg`}
                    control={
                      <Radio
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                      />
                    }
                    required
                    onChange={() =>
                      setSecondVote(`voto_2_crianca_${elements}.jpg`)
                    }
                    label={
                      <>
                        <div className={style.divImg}>
                          <img
                            src={`https://intranet.santolabs.com.br/uploads/voto_2_crianca_${elements}.jpg`}
                            alt="desenho"
                            className={style.img}
                            height="350"
                          ></img>
                        </div>
                      </>
                    }
                  />
                ))}
              </RadioGroup>
            </ThemeProvider>

            <div className={style.divBtn}>
              <button
                type="submit"
                className={style.button}
                disabled={secondVote ? false : true}
                onClick={(event) => handleSubmit(event)}
              >
                Concluir
              </button>
            </div>
          </div>
        </FormControl>
      </form>
    </>
  );
}
