import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask';

function DateBirthy(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
			showMask={false}
			guide={false}
    />
  );
}

function CpfMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
			showMask={false}
			guide={false}
    />
  );
}

function RgMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/]}
      placeholderChar={'\u2000'}
			showMask={false}
			guide={false}
    />
  );
}

function CepMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
			showMask={false}
			guide={false}
    />
  );
}

function PhoneMask(props) {
  const { inputRef, value, ...other } = props;
  let mask = ['(', /\d/, /\d/, ')', ' ',/\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  if(value[5] !== '9') {
    mask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/,/\d/,/\d/]
  } 

  
  

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      value={value}
      mask={mask}
      placeholderChar={'\u2000'}
			showMask={false}
			guide={false}
    />
  );
}

export { DateBirthy, CpfMask, RgMask, CepMask, PhoneMask }

DateBirthy.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

CpfMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

RgMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

CepMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};