import React, { useEffect, useState } from 'react';
import api from '../../../Services/api'

import { Box, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Hidden, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@material-ui/core';

import Manager from '../../../Components/Manager';
import { CustomButtom, TitleList, ContentManager, IconCustom } from '../../../Components/Customs';
import { Add, ArrowBack, Delete, Done, Edit } from '@material-ui/icons';
import { toast } from 'react-toastify';
import stylePagesManager from '../style';


function Departaments() {

	const style = stylePagesManager()

	const [sectors, setSectors] = useState([])
	const [rows, setRows] = useState([])
	const [data, setData] = useState({
		id: 0,
		name: '',
		sector_id: 0
	})
	const [modal, setModal] = useState(false)
	const [modalDelete, setModalDelete] = useState(false)

	async function getSectors() {
		const res = await api.get('sector/listAll')
		setSectors(res.data.rows)
	}

	async function getDepartaments() {
		const res = await api.get('departament/listAll')
		setRows(res.data)
	}

	function handleModal(data) {
		setData({
			id: data.id,
			name: data.departament_name,
			sector_id: data.sector_id
		})
		setModal(true)
	}

	function handleModalDelete(data) {
		console.log(data)
		setData({
			id: data.id,
			name: data.departament_name,
			sector_id: data.sector_id
		})
		setModalDelete(true)
	}

	function handleClose() {
		setData({
			id: 0,
			name: ''
		})
		setModal(false)
		setModalDelete(false)
	}

	function handleValues(e) {
		const { name, value } = e.target
		setData({ ...data, [name]: value })
	}

	function handleSector(e) {
		setData({ ...data, sector_id: e.target.value })
	}

	async function deleteDepartament() {
		try {
			await api.delete(`departament/${data.id}`)
			toast.success('Departamento deletado com sucesso')
			getDepartaments()
		} catch (error) {
			toast.error('Falha ao deletar departamento')
		}

		handleClose()
	}

	async function envDepartament(e) {
		e.preventDefault()
		try {
			const config = {
				type: 'put',
				url: `departament/${data.id}`
			}
			if (!data.id) {
				delete data.id
				config.type = 'post'
				config.url = 'departament'
			}
			await api[config.type](config.url, data)
			getDepartaments()
			toast.success(`Departamento ${data.id ? 'atualizado' : 'cadastrado'} com sucesso!`)
			handleClose()
		} catch (error) {
			toast.error(`Falha ao ${data.id ? 'atualizar' : 'cadastrar'} Departamento!`)
		}
	}

	useEffect(() => {
		getDepartaments()
		getSectors()
	}, [])

	return (
		<>
			<Manager>
				<Container maxWidth="xl" className={style.root}>
					<Box display='flex' justifyContent='center'>
						<ContentManager width={'70%'}>
							<Box display='flex' justifyContent='space-between' alignItems='center'>
								<TitleList>Departamentos</TitleList>

								<CustomButtom
									size='large'
									bg='#f4393c'
									startIcon={<Add />}
									onClick={handleModal}
									className={style.btnAdd}
								>
									Adicionar
						</CustomButtom>
							</Box>
							<Box display='flex' justifyContent='center'>
								<table className={style.table} cellSpacing={0} cellPadding={0}>
									<thead>
										<tr>
											<Hidden smDown>
												<th className={`title`}>ID</th>
											</Hidden>
											<th className={`title`}>Departamento</th>
											<th className={`title`}>setor</th>
											<th className={`title settings`}>Opções</th>
										</tr>
									</thead>

									<tbody>
										{rows.map((row) => (
											<tr key={row.id}>
												<Hidden smDown>
													<td>{row.id}</td>
												</Hidden>

												<td>{row.departament_name}</td>
												<td>{row.sector_name}</td>
												<td className='settings'>
													<IconCustom coloricon="orange" onClick={e => handleModal(row)}>
														<Edit />
													</IconCustom>

													<IconCustom coloricon="red" onClick={e => handleModalDelete(row)}>
														<Delete />
													</IconCustom>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</Box>
						</ContentManager>
					</Box>
				</Container>
			</Manager>

			<Dialog open={modal} onClose={handleClose} aria-labelledby='form-dpto' className={style.modalForm}>
				<DialogTitle id='form-dpto'>{data.id ? 'Editar' : 'Cadastrar'} Departamento</DialogTitle>
				<form onSubmit={envDepartament}>
					<DialogContent>

						<FormControl variant="outlined" fullWidth>
							<InputLabel>Nome do departamento</InputLabel>
							<OutlinedInput
								name="name"
								onChange={handleValues}
								defaultValue={data.name}
								label="Nome do departamento"
							/>
						</FormControl>

						<FormControl variant="outlined" fullWidth>
							<InputLabel htmlFor="select-sector">Setores</InputLabel>
							<Select
								id="select-sector"
								defaultValue={data.sector_id}
								name={'sector_id'}
								onChange={handleSector}
								label="Setores"
							>
								{sectors.map((sector) => (
									<MenuItem key={sector.id} value={sector.id}>
										<em>{sector.name}</em>
									</MenuItem>
								))}
							</Select>
						</FormControl>

					</DialogContent>
					<DialogActions>
						<CustomButtom
							startIcon={<ArrowBack />}
							onClick={handleClose}
						>
							voltar
						</CustomButtom>

						<CustomButtom
							bg="green"
							endIcon={<Done />}
							type='submit'
						>
							{data.id ? 'salvar' : 'cadastrar'}
						</CustomButtom>
					</DialogActions>
				</form>
			</Dialog>

			<Dialog open={modalDelete} onClose={handleClose} aria-labelledby='form-dpto' className={style.modalForm}>
				<DialogTitle id='form-dpto'>Deletar Departamento</DialogTitle>
				<DialogContent>
					<Typography className='content_modal_delete'>Deseja realmente deletar o departamento {data.name} </Typography>
				</DialogContent>
				<DialogActions>
					<CustomButtom
						bg="orange"
						startIcon={<ArrowBack />}
						onClick={handleClose}
					>
						voltar
						</CustomButtom>

					<CustomButtom
						bg="red"
						endIcon={<Delete />}
						onClick={deleteDepartament}
					>
						deletar
						</CustomButtom>
				</DialogActions>
			</Dialog>


		</>
	)
}

export default Departaments;