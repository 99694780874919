import React, { useEffect } from 'react';

import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { TitlePage } from '../../Components/Customs';

import Agilidade from '../../assets/img/agilidade.jpg'
import Determinacao from '../../assets/img/determinacao.jpg'
import Simpatia from '../../assets/img/simpatia.jpg'
import Humildade from '../../assets/img/humildade.jpg'
import Respeito from '../../assets/img/respeito.jpg'
import Facilitador from '../../assets/img/facilitador.jpg'
import Inovacao from '../../assets/img/inovacao.jpg'
import Positividade from '../../assets/img/positividade.jpg'

import { Box, Container, Typography } from '@material-ui/core';

import ReactGa from 'react-ga'

import stylePageMission from './style';

function Mission() {

	const style = stylePageMission()

	useEffect(() => {
		ReactGa.pageview('/missão-e-valores')
	}, [])

	return (
		<>
			<Header />
			<Container maxWidth="md" className={style.root}>
				<Box>
					<TitlePage>Missão</TitlePage>
					<Typography className={style.content}>
						Promover oportunidades, facilitando, incentivando e fazendo a diferença
						positiva na vida das pessoas.
					</Typography>
				</Box>

				<Box>
					<TitlePage>Visão</TitlePage>
					<Typography className={style.content} >
						Ser a empresa referência no Brasil no mercado de confeitaria e festas por
						sua inovação e domínio do mercado online.
					</Typography>
				</Box>

				<Box>
					<TitlePage>Valores</TitlePage>

					<Box display='flex' flexWrap='wrap' justifyContent="space-between" className={style.boxImgValues}>

						<Box className={style.imgValues} component="a" href="#agilidade">
							<div>
								<img src={Agilidade} alt="Agilidade" title="Agilidade" />
							</div>
						</Box>

						<Box className={style.imgValues} component="a" href="#determinacao">
							<div>
								<img src={Determinacao} alt="Determinacao" title="Determinacao" />
							</div>
						</Box>

						<Box className={style.imgValues} component="a" href="#simpatia">
							<div>
								<img src={Simpatia} alt="Simpatia" title="Simpatia" />
							</div>
						</Box>

						<Box className={style.imgValues} component="a" href="#humildade">
							<div>
								<img src={Humildade} alt="Humildade" title="Humildade" />
							</div>
						</Box>

						<Box className={style.imgValues} component="a" href="#respeito">
							<div>
								<img src={Respeito} alt="Respeito" title="Respeito" />
							</div>
						</Box>

						<Box className={style.imgValues} component="a" href="#ser-facilitador">
							<div>
								<img src={Facilitador} alt="Facilitador" title="Facilitador" />
							</div>
						</Box>

						<Box className={style.imgValues} component="a" href="#inovacao">
							<div>
								<img src={Inovacao} alt="Inovação" title="Inovação" />
							</div>
						</Box>

						<Box className={style.imgValues} component="a" href="#positividade">
							<div>
								<img src={Positividade} alt="Positividade" title="Positividade" />
							</div>
						</Box>

					</Box>

					<Box className={style.content} >

						<Typography id='agilidade' >
							<b>Agilidade:</b> Tomar ações e encontrar soluções no dia a dia
							sempre buscando a forma mais rápida possível.
						</Typography>

						<Typography id="determinacao">
							<b>Determinação/Resiliência:</b> Não desistir no primeiro "não" de
							dificuldades que encontrar no caminho, visar o resultado que buscar,
							ir atrás com empenho e dedicação. Alcançar os resultados esperados
							cumprindo suas responsabilidades e atingindo suas metas e objetivos.
						</Typography>

						<Typography id="simpatia">
							<b>Simpatia:</b> Receber ás pessoas com um sorriso no rosto e
							tratar a todos com educação e gentileza.
						</Typography>

						<Typography id="humildade">
							<b>Humildade:</b> Características de pessoas francas, que aceitam a verdade e
							tem senso de realidade apurada. Reconhecem seus erros e acertos com a mesma naturalidade.
							Não subestimam, nem supervalorizam fatos, pessoas, coisas ou a si mesmo!
						</Typography>

						<Typography id="respeito">
							<b>Respeito:</b> Consideração; ato de obedecer leis, regras.
						</Typography>

						<Typography id='ser-facilitador'>
							<b>Ser facilitador:</b> Tornar a vida das pessoas o mais fácil possível,
							seja através de sugestões criativas ou soluções para os seus problemas. Escutar
							com atenção o que as pessoas estão precisando e dar ajuda necessária de forma
							mais simples e rápida, sejam clientes internos ou externos.
						</Typography>

						<Typography id="inovacao">
							<b>Inovação:</b> Inovar em tudo o que fizer, buscando produtos novos no mercado
							para serem lançados na loja ou visando melhorar algum processo interno do seu dia
							a dia dentro da empresa.
						</Typography>

						<Typography id="positividade">
							<b>Positividade:</b> Olhar o ponto positivo de tudo, inclusive das dificuldades,
							para tirar aprendizados e oportunidades de crescimento e desenvolvimento.
						</Typography>

					</Box>

				</Box>
			</Container>
			<Footer />
		</>
	)
}

export default Mission;